import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import DetalleAgencia from './detalle-agencia.component';

class MantenimientoList extends Component {
    state = {
        agencias: [],
        currentAgencia: null,
        currentIndex: -1,
        mantenimientos: [],
        mantenimientosDeAgencia: [],
    };

    componentDidMount() {
        this.fetchDataFromFirestore();
    }

    async fetchDataFromFirestore() {
        const db = firebase.firestore();

        // Obtener agencias
        const agenciasSnapshot = await db.collection('agencias').get();
        const agencias = agenciasSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));

        // Obtener mantenimientos
        const mantenimientosSnapshot = await db.collection('mantenimientos').get();
        const mantenimientos = mantenimientosSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));

        this.setState({ agencias, mantenimientos });
    }

    setActiveAgencia(agencia, index) {
        const { mantenimientos } = this.state;

        // Filtra los mantenimientos por agencia
        const mantenimientosDeAgencia = mantenimientos.filter(
            (mantenimiento) => mantenimiento.numAgencia === agencia.numAgencia
        );

        // Calcula el total de mantenimientos
        const totalMantenimientos = mantenimientosDeAgencia.length;

        // Actualiza el estado, incluyendo agenciaId
        this.setState({
            currentAgencia: { ...agencia, totalMantenimientos },
            currentIndex: index,
            mantenimientosDeAgencia,
            agenciaId: agencia.id,
        });
    }

    render() {
        const { agencias, currentAgencia, currentIndex } = this.state;

        return (
            <div className="list row wrapper">
                <div className="col-md-6">
                    <h4>Lista de agencias</h4>
                    <ul className="list-group">
                        {agencias &&
                            agencias.map((agencia, index) => (
                                <li
                                    className={
                                        "list-group-item " +
                                        (index === currentIndex ? "active" : "")
                                    }
                                    onClick={() => this.setActiveAgencia(agencia, index)}
                                    key={index}
                                >
                                    {agencia.numAgencia}
                                </li>
                            ))}
                    </ul>
                </div>
                <div className="col-md-6">
                    {currentAgencia ? (
                        <DetalleAgencia agenciaId={currentAgencia.id} />
                    ) : (
                        <div>
                            <br />
                            <p>Pulsa para examinar agencia...</p>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default MantenimientoList;
