import * as firebase from "firebase";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyABxUFxwviPs4KvPiU7rl1kOAQzR8yTaUI",
  authDomain: "mttodt15v6-ba36d.firebaseapp.com",
  projectId: "mttodt15v6-ba36d",
  storageBucket: "mttodt15v6-ba36d.appspot.com",
  messagingSenderId: "487807005824",
  appId: "1:487807005824:web:7eb548cbf211145e986265"
};

firebase.initializeApp(config);

export default firebase.firestore();
