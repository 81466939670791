import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';

class Login extends Component {
    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
            error: null,
        };
    }

    handleInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value, error: null });
    }

    handleLogin = () => {
        const { email, password } = this.state;

        firebase.auth().signInWithEmailAndPassword(email, password)
            .then(userCredential => {
                // El usuario ha iniciado sesión con éxito
                const user = userCredential.user;
                // Realiza acciones adicionales, como redirigir al usuario a la página principal.
            })
            .catch(error => {
                let errorMessage;
                switch (error.code) {
                    case 'auth/user-not-found':
                        errorMessage = 'Usuario no registrado. Por favor, regístrate.';
                        break;
                    case 'auth/wrong-password':
                        errorMessage = 'Contraseña incorrecta. Verifica tus credenciales.';
                        break;
                    default:
                        errorMessage = 'Hubo un error al iniciar sesión. Por favor, inténtalo de nuevo.';
                        break;
                }

                this.setState({ error: errorMessage });
            });
    }

    render() {
        return (
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="card wrapper">
                            <div className="card-body">
                                <h1 className="card-title text-center">Iniciar sesión</h1>
                                <div className="form-group">
                                    <label htmlFor="email">Correo electrónico</label>
                                    <input
                                        type="email"
                                        name="email"
                                        className="form-control"
                                        placeholder="Correo electrónico"
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Contraseña</label>
                                    <input
                                        type="password"
                                        name="password"
                                        className="form-control"
                                        placeholder="Contraseña"
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                                <button
                                    onClick={this.handleLogin}
                                    className="btn btn-primary btn-block"
                                >
                                    Entrar
                                </button>
                                {this.state.error && (
                                    <p className="text-danger mt-3 text-center">{this.state.error}</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
