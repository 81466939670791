import React, { Component } from "react";
import AgenciasDataService from "../services/agencias.service";

export default class Agencia extends Component {
    constructor(props) {
        super(props);
        this.onChangeNombre = this.onChangeNombre.bind(this);
        this.onChangeNumero = this.onChangeNumero.bind(this);
        this.onChangeEstado = this.onChangeEstado.bind(this);
        this.onChangeUbicacion = this.onChangeUbicacion.bind(this);
        this.updateAgencia = this.updateAgencia.bind(this);
        this.deleteAgencia = this.deleteAgencia.bind(this);

        this.state = {
            currentAgencia: {
                id: null,
                // nombreAgencia: "",
                numAgencia: "",
                estadoAgencia: "",
                ubiAgencia: "",
            },
            message: "",
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { agencia } = nextProps;
        if (prevState.currentAgencia.id !== agencia.id) {
            return {
                currentAgencia: agencia,
                message: ""
            };
        }

        return prevState.currentAgencia;
    }

    componentDidMount() {
        this.setState({
            currentAgencia: this.props.agencia,
        });
    }

    onChangeNombre(e) {
        // const nombreAgencia = e.target.value;

        this.setState(function (prevState) {
            return {
                currentAgencia: {
                    ...prevState.currentAgencia,
                    // nombreAgencia: nombreAgencia,
                },
            };
        });
    }

    onChangeNumero(e) {
        const numAgencia = e.target.value;

        this.setState((prevState) => ({
            currentAgencia: {
                ...prevState.currentAgencia,
                numAgencia: numAgencia,
            },
        }));
    }

    onChangeEstado(e) {
        const estadoAgencia = e.target.value;

        this.setState((prevState) => ({
            currentAgencia: {
                ...prevState.currentAgencia,
                estadoAgencia: estadoAgencia,
            },
        }));
    }

    onChangeUbicacion(e) {
        const ubiAgencia = e.target.value;

        this.setState((prevState) => ({
            currentAgencia: {
                ...prevState.currentAgencia,
                ubiAgencia: ubiAgencia,
            },
        }));
    }


    updateAgencia() {
        const data = {
            // nombreAgencia: this.state.currentAgencia.nombreAgencia,
            numAgencia: this.state.currentAgencia.numAgencia,
            estadoAgencia: this.state.currentAgencia.estadoAgencia,
            ubiAgencia: this.state.currentAgencia.ubiAgencia,
        };

        AgenciasDataService.update(this.state.currentAgencia.id, data)
            .then(() => {
                this.setState({
                    message: "¡La agencia se actualizó exitosamente!",
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    deleteAgencia() {
        AgenciasDataService.delete(this.state.currentAgencia.id)
            .then(() => {
                this.props.refreshList();
            })
            .catch((e) => {
                console.log(e);
            });
    }

    render() {
        const { currentAgencia } = this.state;

        return (
            <div>
                <h4 className="text-sm-left text-center">Agencia</h4>
                {currentAgencia ? (
                    <div className="edit-form">
                        <form>
                            {/* <div className="form-group">
                                <label htmlFor="nombreAgencia">Nombre de Agencia</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="nombreAgencia"
                                    value={currentAgencia.nombreAgencia}
                                    onChange={this.onChangeNombre}
                                />
                            </div> */}
                            <div className="form-group">
                                <label htmlFor="numAgencia">Número de Agencia</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="numAgencia"
                                    value={currentAgencia.numAgencia}
                                    onChange={this.onChangeNumero}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="estadoAgencia">Estado de Agencia</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="estadoAgencia"
                                    value={currentAgencia.estadoAgencia}
                                    onChange={this.onChangeEstado}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="ubiAgencia">Ubicación de Agencia</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="ubiAgencia"
                                    value={currentAgencia.ubiAgencia}
                                    onChange={this.onChangeUbicacion}
                                />
                            </div>
                        </form>

                        <button
                            className="badge badge-danger mr-2"
                            onClick={this.deleteAgencia}
                        >
                            Borrar
                        </button>

                        <button
                            type="submit"
                            className="badge badge-success"
                            onClick={this.updateAgencia}
                        >
                            Actualizar
                        </button>
                        <p>{this.state.message}</p>
                    </div>
                ) : (
                    <div>
                        <br />
                        <p>Pulsa para examinar agencia...</p>
                    </div>
                )}
            </div>
        );
    }
}
