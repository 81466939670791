import firebase from '../firebase';

const bd = firebase.collection("/agencias");

class AgenciasDataService {
    getAll() {
        return bd;
    }

    create(agencias) {
        return bd.add(agencias);
    }

    update(id, value) {
        return bd.doc(id).update(value);
    }

    delete(id) {
        return bd.doc(id).delete();
    }
}

export default new AgenciasDataService();