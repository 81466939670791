import React, { Component } from "react";
import MantenimientosDataService from "../services/mantenimientos.service";
import AgenciasDataService from "../services/agencias.service";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import firebase from "firebase/app";
import "firebase/storage";

export default class AddMantenimiento extends Component {
    constructor(props) {
        super(props);
        this.onChangeFecha = this.onChangeFecha.bind(this);
        this.onChangeHora = this.onChangeHora.bind(this);
        this.onChangeAgencia = this.onChangeAgencia.bind(this);
        this.onChangeNombre = this.onChangeNombre.bind(this);
        this.onChangeNumero = this.onChangeNumero.bind(this);
        this.onChangeEstado = this.onChangeEstado.bind(this);
        this.onChangeUbicacion = this.onChangeUbicacion.bind(this);
        this.onChangeTipoMantenimiento = this.onChangeTipoMantenimiento.bind(this);
        this.onChangeDescripcionIncidencias = this.onChangeDescripcionIncidencias.bind(this);
        this.onChangeTrabajoRealizado = this.onChangeTrabajoRealizado.bind(this);
        // this.handleFirmaChange = this.handleFirmaChange.bind(this);
        this.saveMantenimiento = this.saveMantenimiento.bind(this);
        this.newMantenimiento = this.newMantenimiento.bind(this);

        this.state = {
            seccionActual: 1,
            sistemaActual: 0,
            enviadoExitosamente: false,
            fechaMantenimiento: null,
            fechaError: "",
            horaMantenimiento: "12:00",
            // nombreAgencia: "",
            numAgencia: "",
            estadoAgencia: "",
            ubiAgencia: "",
            tipoMantenimiento: "",
            descripcionIncidencias: "",
            trabajoRealizado: "",
            sistemaCamara: [
                {
                    tipo: "Camaras",
                    cantidad: 0,
                    cantidadBueno: 0,
                    cantidadMalo: 0,
                },
                {
                    tipo: "DVR",
                    cantidad: 0,
                    cantidadBueno: 0,
                    cantidadMalo: 0,
                },
                {
                    tipo: "Fuente de Poder",
                    cantidad: 0,
                    cantidadBueno: 0,
                    cantidadMalo: 0,
                },
                {
                    tipo: "Monitor y Teclado",
                    cantidad: 0,
                    cantidadBueno: 0,
                    cantidadMalo: 0,
                },
                {
                    tipo: "Microfonos",
                    cantidad: 0,
                    cantidadBueno: 0,
                    cantidadMalo: 0,
                },
                {
                    tipo: "UPS",
                    cantidad: 0,
                    cantidadBueno: 0,
                    cantidadMalo: 0,
                },
                {
                    tipo: "Condiciones Cuarto de Servidores",
                    cantidad: 0,
                    cantidadBueno: 0,
                    cantidadMalo: 0,
                },
            ],
            sistemaAlarma: [
                {
                    tipo: "Central Sistema Anti Robo",
                    cantidad: 0,
                    cantidadBueno: 0,
                    cantidadMalo: 0,
                },
                {
                    tipo: "Discador Telefonico",
                    cantidad: 0,
                    cantidadBueno: 0,
                    cantidadMalo: 0,
                },
                {
                    tipo: "Teclados",
                    cantidad: 0,
                    cantidadBueno: 0,
                    cantidadMalo: 0,
                },
                {
                    tipo: "Sirena y Luz Estroboscopica",
                    cantidad: 0,
                    cantidadBueno: 0,
                    cantidadMalo: 0,
                },
                {
                    tipo: "Sensor Antisismico",
                    cantidad: 0,
                    cantidadBueno: 0,
                    cantidadMalo: 0,
                },
                {
                    tipo: "Sensor Rotura de Vidrio",
                    cantidad: 0,
                    cantidadBueno: 0,
                    cantidadMalo: 0,
                },
                {
                    tipo: "Sensor de Movimiento",
                    cantidad: 0,
                    cantidadBueno: 0,
                    cantidadMalo: 0,
                },
                {
                    tipo: "Pulsador de Pie",
                    cantidad: 0,
                    cantidadBueno: 0,
                    cantidadMalo: 0,
                },
                {
                    tipo: "Pulsador de Mano",
                    cantidad: 0,
                    cantidadBueno: 0,
                    cantidadMalo: 0,
                },
                {
                    tipo: "Fuente de Poder",
                    cantidad: 0,
                    cantidadBueno: 0,
                    cantidadMalo: 0,
                },
                {
                    tipo: "Prueba de Baterías",
                    cantidad: 0,
                    cantidadBueno: 0,
                    cantidadMalo: 0,
                },
            ],
            sistemaControlAcceso: [
                {
                    tipo: "Teclados",
                    cantidad: 0,
                    cantidadBueno: 0,
                    cantidadMalo: 0,
                },
                {
                    tipo: "Lectoras",
                    cantidad: 0,
                    cantidadBueno: 0,
                    cantidadMalo: 0,
                },
                {
                    tipo: "Tarjeta Esclusa",
                    cantidad: 0,
                    cantidadBueno: 0,
                    cantidadMalo: 0,
                },
                {
                    tipo: "Cerraduras Electromagnéticas",
                    cantidad: 0,
                    cantidadBueno: 0,
                    cantidadMalo: 0,
                },
                {
                    tipo: "Sensores Magnéticos",
                    cantidad: 0,
                    cantidadBueno: 0,
                    cantidadMalo: 0,
                },
                {
                    tipo: "Botones de Salida",
                    cantidad: 0,
                    cantidadBueno: 0,
                    cantidadMalo: 0,
                },
                {
                    tipo: "Fuente de Poder",
                    cantidad: 0,
                    cantidadBueno: 0,
                    cantidadMalo: 0,
                },
                {
                    tipo: "Prueba de Baterías",
                    cantidad: 0,
                    cantidadBueno: 0,
                    cantidadMalo: 0,
                },
                {
                    tipo: "Caja Trampa",
                    cantidad: 0,
                    cantidadBueno: 0,
                    cantidadMalo: 0,
                },
            ],
            sistemaContraIncendio: [
                {
                    tipo: "Central de Incendio",
                    cantidad: 0,
                    cantidadBueno: 0,
                    cantidadMalo: 0,
                },
                {
                    tipo: "Sensores de Humo y Fuego",
                    cantidad: 0,
                    cantidadBueno: 0,
                    cantidadMalo: 0,
                },
                {
                    tipo: "Difusor de Sonido",
                    cantidad: 0,
                    cantidadBueno: 0,
                    cantidadMalo: 0,
                },
                {
                    tipo: "Lamparas de Emergencia",
                    cantidad: 0,
                    cantidadBueno: 0,
                    cantidadMalo: 0,
                },
                {
                    tipo: "Fuente de Poder",
                    cantidad: 0,
                    cantidadBueno: 0,
                    cantidadMalo: 0,
                },
                {
                    tipo: "Prueba de Baterías",
                    cantidad: 0,
                    cantidadBueno: 0,
                    cantidadMalo: 0,
                },
            ],
            observacionGeneralCamara: "",
            observacionGeneralAlarma: "",
            observacionGeneralControlAcceso: "",
            observacionGeneralContraIncendio: "",
            // observacionGeneralOtros: "",
            llamadaConsolaSeguridad: [
                {
                    discador: {
                        estado: "", // Puede ser "positivo" o "negativo"
                    },
                    rps: {
                        estado: "", // Puede ser "positivo" o "negativo"
                    },
                    cctv: {
                        estado: "", // Puede ser "positivo" o "negativo"
                    },
                    audio: {
                        estado: "", // Puede ser "positivo" o "negativo"
                    },
                    consola: "",
                    operador: "",
                    observacionesTelefonia: "",
                }
            ],
            // Agrega otros sistemas aquí...
            observaciones: "",
            dt15v6NombreApellido: "",
            dt15v6Cargo: "",
            clienteNombreApellido: "",
            clienteCargo: "",
            soporteFotografico: [],
            // recomendaciones: "",
            tipoEquipoActual: null,
            submitted: false,
            agencias: [],
        };
    }

    componentDidMount() {
        AgenciasDataService.getAll().onSnapshot((querySnapshot) => {
            const agencias = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                agencias.push({
                    id: doc.id,
                    ...data,
                });
            });
            this.setState({
                agencias,
            });
        });
    }

    // Función para manejar el avance a la siguiente sección
    handleSiguiente = () => {
        // Avanzar a la siguiente sección
        this.setState((prevState) => ({
            seccionActual: prevState.seccionActual + 1,
        }), () => {
            // Desplazar la página hacia arriba después de actualizar el estado
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        });
    };

    handleAtras = () => {
        this.setState((prevState) => ({
            seccionActual: prevState.seccionActual - 1,
        }));
    };

    onChangeFecha = (date) => {
        const fechaActual = new Date();
        const fechaLimiteMinima = new Date();
        const fechaLimiteMaxima = new Date();

        fechaLimiteMinima.setDate(fechaActual.getDate() - 15);
        fechaLimiteMaxima.setDate(fechaActual.getDate() + 15);

        if (date < fechaLimiteMinima || date > fechaLimiteMaxima) {
            this.setState({
                fechaError: "La fecha debe estar dentro de los próximos 15 días.",
                fechaMantenimiento: date
            });
        } else {
            this.setState({
                fechaError: "",
                fechaMantenimiento: date
            });
        }
    };


    onChangeHora(hora) {
        this.setState({
            horaMantenimiento: hora,
        });
    }

    onChangeTipoMantenimiento = (e) => {
        this.setState({
            tipoMantenimiento: e.target.value,
        });
    }

    onChangeDescripcionIncidencias(e) {
        this.setState({
            descripcionIncidencias: e.target.value,
        });
    }

    onChangeTrabajoRealizado(e) {
        this.setState({
            trabajoRealizado: e.target.value,
        });
    }

    onChangeAgencia(e) {
        const selectedAgenciaId = e.target.value;
        const selectedAgencia = this.state.agencias.find((agencia) => agencia.id === selectedAgenciaId);

        if (selectedAgencia) {
            this.setState({
                // nombreAgencia: selectedAgencia.nombreAgencia,
                numAgencia: selectedAgencia.numAgencia,
                estadoAgencia: selectedAgencia.estadoAgencia,
                ubiAgencia: selectedAgencia.ubiAgencia,
            });

            console.log("Número de Agencia:", selectedAgencia.numAgencia);
        } else {
            this.setState({
                // nombreAgencia: "",
                numAgencia: "",
                estadoAgencia: "",
                ubiAgencia: "",
            });
        }
    }

    // Función para dar formato al tiempo como HH:MM:SS
    formatTiempo = (inputValue) => {
        // Elimina cualquier carácter que no sea un número o dos puntos (:)
        const cleanedInput = inputValue.replace(/[^\d:]/g, "");

        // Divide el tiempo en partes (horas, minutos y segundos)
        const parts = cleanedInput.split(":").map((part) => parseInt(part, 10));

        // Asegúrate de que haya al menos una parte
        if (parts.length === 0) {
            return "";
        }

        // Añade partes faltantes para obtener el formato HH:MM:SS
        const formattedTime = parts
            .map((part) => {
                return part < 10 ? `0${part}` : `${part}`;
            })
            .join(":");

        return formattedTime;
    };

    onChangeNombre(e) {
        this.setState({
            // nombreAgencia: e.target.value,
        });
    }

    onChangeNumero(e) {
        this.setState({
            numAgencia: e.target.value,
        });
    }

    onChangeEstado(e) {
        this.setState({
            estadoAgencia: e.target.value,
        });
    }

    onChangeUbicacion(e) {
        this.setState({
            ubiAgencia: e.target.value,
        });
    }

    agregarInstancia = (sistemaIndex, equipoIndex) => {
        const sistemaCamara = [...this.state.sistemaCamara];

        if (
            sistemaCamara[sistemaIndex] &&
            sistemaCamara[sistemaIndex].equipos &&
            sistemaCamara[sistemaIndex].equipos[equipoIndex]
        ) {
            const nuevoEquipo = {
                cantidad: 0,
                marca: "",
                modelo: "",
                enFuncionamiento: 0,
                danado: 0,
            };

            sistemaCamara[sistemaIndex].equipos[equipoIndex].instancias.push(nuevoEquipo);
            this.setState({ sistemaCamara });
        }
    };

    agregarInstanciaCamara = (equipoIndex) => {
        const sistemaCamara = [...this.state.sistemaCamara];
        sistemaCamara[equipoIndex].instancias.push({
            cantidad: 0,
            marca: "",
            modelo: "",
            enFuncionamiento: 0,
            danado: 0,
        });
        this.setState({ sistemaCamara });
    };

    agregarInstanciaAlarma = (equipoIndex) => {
        const sistemaAlarma = [...this.state.sistemaAlarma];
        sistemaAlarma[equipoIndex].instancias.push({
            cantidad: 0,
            marca: "",
            modelo: "",
            enFuncionamiento: 0,
            danado: 0,
        });
        this.setState({ sistemaAlarma });
    };

    agregarInstanciaControlAcceso = (equipoIndex) => {
        const sistemaControlAcceso = [...this.state.sistemaControlAcceso];
        sistemaControlAcceso[equipoIndex].instancias.push({
            cantidad: 0,
            marca: "",
            modelo: "",
            enFuncionamiento: 0,
            danado: 0,
        });
        this.setState({ sistemaControlAcceso });
    };

    agregarInstanciaContraIncendio = (equipoIndex) => {
        const sistemaContraIncendio = [...this.state.sistemaContraIncendio];
        sistemaContraIncendio[equipoIndex].instancias.push({
            cantidad: 0,
            marca: "",
            modelo: "",
            enFuncionamiento: 0,
            danado: 0,
        });
        this.setState({ sistemaContraIncendio });
    };

    cambiarSistemaActual = (nuevoSistema) => {
        this.setState({ sistemaActual: nuevoSistema });
    };


    saveMantenimiento = async () => {
        const { numAgencia, estadoAgencia, ubiAgencia, fechaMantenimiento, horaMantenimiento, tipoMantenimiento, descripcionIncidencias, trabajoRealizado, sistemaCamara, sistemaAlarma, sistemaControlAcceso, sistemaContraIncendio, observacionGeneralCamara, observacionGeneralAlarma, observacionGeneralControlAcceso, observacionGeneralContraIncendio, llamadaConsolaSeguridad, observaciones, dt15v6NombreApellido, dt15v6Cargo, clienteNombreApellido, clienteCargo, soporteFotografico } = this.state;

        if (!numAgencia || !estadoAgencia || !ubiAgencia || !fechaMantenimiento || !tipoMantenimiento || !descripcionIncidencias || !trabajoRealizado || !sistemaCamara || !sistemaAlarma || !sistemaControlAcceso || !sistemaContraIncendio || !llamadaConsolaSeguridad || !observaciones || !dt15v6NombreApellido || !dt15v6Cargo || !clienteNombreApellido || !clienteCargo || !soporteFotografico) {
            alert("Por favor, complete todos los campos.");
            return;
        }

        const horaFormateada = `${horaMantenimiento}:${"00"}`;

        // Subir las firmas al Storage de Firebase
        const storage = firebase.storage();

        try {
            const soporteFotograficoUrls = await Promise.all(soporteFotografico.map(async (imagen) => {
                return await this.uploadImagen(imagen);
            }));

            // Formatear los datos para guardar en Firestore
            const data = {
                // nombreAgencia,
                numAgencia,
                estadoAgencia,
                ubiAgencia,
                fechaMantenimiento: this.state.fechaMantenimiento,
                horaMantenimiento,
                tipoMantenimiento,
                descripcionIncidencias,
                trabajoRealizado,
                sistemaCamara,
                sistemaAlarma,
                sistemaControlAcceso,
                sistemaContraIncendio,
                observacionGeneralCamara,
                observacionGeneralAlarma,
                observacionGeneralControlAcceso,
                observacionGeneralContraIncendio,
                llamadaConsolaSeguridad,
                observaciones,
                dt15v6: {
                    nombreApellido: dt15v6NombreApellido,
                    cargo: dt15v6Cargo,
                },
                cliente: {
                    nombreApellido: clienteNombreApellido,
                    cargo: clienteCargo,
                },
                soporteFotografico: soporteFotograficoUrls,
            };

            // Guardar en Firestore
            await MantenimientosDataService.create(data)
                .then(() => {
                    console.log("Nuevo mantenimiento creado con éxito!");
                    this.setState({
                        submitted: true,
                        enviadoExitosamente: true,
                        mostrarMensaje: true,  // Agrega esta línea
                    });
                })
                .catch((e) => {
                    console.log(e);
                });

            this.setState({
                numAgencia: "",
                estadoAgencia: "",
                ubiAgencia: "",
                fechaMantenimiento: null,
                horaMantenimiento: "12:00",
                tipoMantenimiento: "",
                descripcionIncidencias: "",
                trabajoRealizado: "",
                sistemaCamara: [],
                sistemaAlarma: [],
                sistemaControlAcceso: [],
                sistemaContraIncendio: [],
                observacionGeneralCamara: "",
                observacionGeneralAlarma: "",
                observacionGeneralControlAcceso: "",
                observacionGeneralContraIncendio: "",
                llamadaConsolaSeguridad: [],
                observaciones: "",
                dt15v6NombreApellido: "",
                dt15v6Cargo: "",
                clienteNombreApellido: "",
                clienteCargo: "",
                soporteFotografico: [],
                submitted: true,
                enviadoExitosamente: true,
            });

            alert('Mantenimiento enviado exitosamente.');
        } catch (error) {
            console.error('Error al enviar el mantenimiento:', error);
            alert('Error al enviar el mantenimiento. Por favor, inténtalo de nuevo.');
        }
    };

    newMantenimiento() {
        this.setState({
            fechaMantenimiento: null,
            horaMantenimiento: "12:00",
            numAgencia: "",
            estadoAgencia: "",
            ubiAgencia: "",
            tipoMantenimiento: "",
            descripcionIncidencias: "",
            trabajoRealizado: "",
            submitted: false,
        });
    }

    handlePropertyChange = (sistema, equipoIndex, property, value) => {
        const sistemas = { ...this.state };
        sistemas[sistema][equipoIndex][property] = value;
        this.setState(sistemas);
    };

    // Handler para cambio en la cantidad de una instancia del sistema de cámaras
    handleCantidadChange = (equipoIndex, e) => {
        const cantidad = parseInt(e.target.value);

        if (!isNaN(cantidad) && cantidad >= 0) {
            const sistemaCamara = [...this.state.sistemaCamara];
            sistemaCamara[equipoIndex].cantidad = cantidad;
            this.setState({ sistemaCamara });
        } else {
            // Si la cantidad no es un número válido o es menor que 0, restablece el valor a 0
            const sistemaCamara = [...this.state.sistemaCamara];
            sistemaCamara[equipoIndex].cantidad = 0;
            this.setState({ sistemaCamara });
        }
    };

    // Handler para cambio en el contador de "Bueno" de una instancia del sistema de cámaras
    handleCantidadBuenoChange = (equipoIndex, e) => {
        const cantidadBueno = parseInt(e.target.value);

        if (!isNaN(cantidadBueno) && cantidadBueno >= 0) {
            const sistemaCamara = [...this.state.sistemaCamara];
            sistemaCamara[equipoIndex].cantidadBueno = cantidadBueno;
            this.setState({ sistemaCamara });
        } else {
            // Si la cantidad no es un número válido o es menor que 0, restablece el valor a 0
            const sistemaCamara = [...this.state.sistemaCamara];
            sistemaCamara[equipoIndex].cantidadBueno = 0;
            this.setState({ sistemaCamara });
        }
    };

    // Handler para cambio en el contador de "Malo" de una instancia del sistema de cámaras
    handleCantidadMaloChange = (equipoIndex, e) => {
        const cantidadMalo = parseInt(e.target.value);

        if (!isNaN(cantidadMalo) && cantidadMalo >= 0) {
            const sistemaCamara = [...this.state.sistemaCamara];
            sistemaCamara[equipoIndex].cantidadMalo = cantidadMalo;
            this.setState({ sistemaCamara });
        } else {
            // Si la cantidad no es un número válido o es menor que 0, restablece el valor a 0
            const sistemaCamara = [...this.state.sistemaCamara];
            sistemaCamara[equipoIndex].cantidadMalo = 0;
            this.setState({ sistemaCamara });
        }
    };

    // Handler para cambio en la cantidad de una instancia del sistema de Anti Robo
    handleCantidadChangeAlarma = (equipoIndex, e) => {
        const cantidad = parseInt(e.target.value);

        if (!isNaN(cantidad) && cantidad >= 0) {
            const sistemaAlarma = [...this.state.sistemaAlarma];
            sistemaAlarma[equipoIndex].cantidad = cantidad;
            this.setState({ sistemaAlarma });
        } else {
            // Si la cantidad no es un número válido o es menor que 0, restablece el valor a 0
            const sistemaAlarma = [...this.state.sistemaAlarma];
            sistemaAlarma[equipoIndex].cantidad = 0;
            this.setState({ sistemaAlarma });
        }
    };

    // Handler para cambio en el contador de "Bueno" de una instancia del sistema de Anti Robo
    handleCantidadBuenoChangeAlarma = (equipoIndex, e) => {
        const cantidadBueno = parseInt(e.target.value);

        if (!isNaN(cantidadBueno) && cantidadBueno >= 0) {
            const sistemaAlarma = [...this.state.sistemaAlarma];
            sistemaAlarma[equipoIndex].cantidadBueno = cantidadBueno;
            this.setState({ sistemaAlarma });
        } else {
            // Si la cantidad no es un número válido o es menor que 0, restablece el valor a 0
            const sistemaAlarma = [...this.state.sistemaAlarma];
            sistemaAlarma[equipoIndex].cantidadBueno = 0;
            this.setState({ sistemaAlarma });
        }
    };

    // Handler para cambio en el contador de "Malo" de una instancia del sistema de Anti Robo
    handleCantidadMaloChangeAlarma = (equipoIndex, e) => {
        const cantidadMalo = parseInt(e.target.value);

        if (!isNaN(cantidadMalo) && cantidadMalo >= 0) {
            const sistemaAlarma = [...this.state.sistemaAlarma];
            sistemaAlarma[equipoIndex].cantidadMalo = cantidadMalo;
            this.setState({ sistemaAlarma });
        } else {
            // Si la cantidad no es un número válido o es menor que 0, restablece el valor a 0
            const sistemaAlarma = [...this.state.sistemaAlarma];
            sistemaAlarma[equipoIndex].cantidadMalo = 0;
            this.setState({ sistemaAlarma });
        }
    };

    // Handler para cambio en la cantidad de una instancia del sistema de Control de Acceso
    handleCantidadChangeControlAcceso = (equipoIndex, e) => {
        const cantidad = parseInt(e.target.value);

        if (!isNaN(cantidad) && cantidad >= 0) {
            const sistemaControlAcceso = [...this.state.sistemaControlAcceso];
            sistemaControlAcceso[equipoIndex].cantidad = cantidad;
            this.setState({ sistemaControlAcceso });
        } else {
            // Si la cantidad no es un número válido o es menor que 0, restablece el valor a 0
            const sistemaControlAcceso = [...this.state.sistemaControlAcceso];
            sistemaControlAcceso[equipoIndex].cantidad = 0;
            this.setState({ sistemaControlAcceso });
        }
    };

    // Handler para cambio en el contador de "Bueno" de una instancia del sistema de Control de Acceso
    handleCantidadBuenoChangeControlAcceso = (equipoIndex, e) => {
        const cantidadBueno = parseInt(e.target.value);

        if (!isNaN(cantidadBueno) && cantidadBueno >= 0) {
            const sistemaControlAcceso = [...this.state.sistemaControlAcceso];
            sistemaControlAcceso[equipoIndex].cantidadBueno = cantidadBueno;
            this.setState({ sistemaControlAcceso });
        } else {
            // Si la cantidad no es un número válido o es menor que 0, restablece el valor a 0
            const sistemaControlAcceso = [...this.state.sistemaControlAcceso];
            sistemaControlAcceso[equipoIndex].cantidadBueno = 0;
            this.setState({ sistemaControlAcceso });
        }
    };

    // Handler para cambio en el contador de "Malo" de una instancia del sistema de Control de Acceso
    handleCantidadMaloChangeControlAcceso = (equipoIndex, e) => {
        const cantidadMalo = parseInt(e.target.value);

        if (!isNaN(cantidadMalo) && cantidadMalo >= 0) {
            const sistemaControlAcceso = [...this.state.sistemaControlAcceso];
            sistemaControlAcceso[equipoIndex].cantidadMalo = cantidadMalo;
            this.setState({ sistemaControlAcceso });
        } else {
            // Si la cantidad no es un número válido o es menor que 0, restablece el valor a 0
            const sistemaControlAcceso = [...this.state.sistemaControlAcceso];
            sistemaControlAcceso[equipoIndex].cantidadMalo = 0;
            this.setState({ sistemaControlAcceso });
        }
    };

    // Handler para cambio en la cantidad de una instancia del sistema de Contra Incendio
    handleCantidadChangeContraIncendio = (equipoIndex, e) => {
        const cantidad = parseInt(e.target.value);

        if (!isNaN(cantidad) && cantidad >= 0) {
            const sistemaContraIncendio = [...this.state.sistemaContraIncendio];
            sistemaContraIncendio[equipoIndex].cantidad = cantidad;
            this.setState({ sistemaContraIncendio });
        } else {
            // Si la cantidad no es un número válido o es menor que 0, restablece el valor a 0
            const sistemaContraIncendio = [...this.state.sistemaContraIncendio];
            sistemaContraIncendio[equipoIndex].cantidad = 0;
            this.setState({ sistemaContraIncendio });
        }
    };

    // Handler para cambio en el contador de "Bueno" de una instancia del sistema de Contra Incendio
    handleCantidadBuenoChangeContraIncendio = (equipoIndex, e) => {
        const cantidadBueno = parseInt(e.target.value);

        if (!isNaN(cantidadBueno) && cantidadBueno >= 0) {
            const sistemaContraIncendio = [...this.state.sistemaContraIncendio];
            sistemaContraIncendio[equipoIndex].cantidadBueno = cantidadBueno;
            this.setState({ sistemaContraIncendio });
        } else {
            // Si la cantidad no es un número válido o es menor que 0, restablece el valor a 0
            const sistemaContraIncendio = [...this.state.sistemaContraIncendio];
            sistemaContraIncendio[equipoIndex].cantidadBueno = 0;
            this.setState({ sistemaContraIncendio });
        }
    };

    // Handler para cambio en el contador de "Malo" de una instancia del sistema de Contra Incendio
    handleCantidadMaloChangeContraIncendio = (equipoIndex, e) => {
        const cantidadMalo = parseInt(e.target.value);

        if (!isNaN(cantidadMalo) && cantidadMalo >= 0) {
            const sistemaContraIncendio = [...this.state.sistemaContraIncendio];
            sistemaContraIncendio[equipoIndex].cantidadMalo = cantidadMalo;
            this.setState({ sistemaContraIncendio });
        } else {
            // Si la cantidad no es un número válido o es menor que 0, restablece el valor a 0
            const sistemaContraIncendio = [...this.state.sistemaContraIncendio];
            sistemaContraIncendio[equipoIndex].cantidadMalo = 0;
            this.setState({ sistemaContraIncendio });
        }
    };

    // ------------------------------

    // Handler para cambio en las observaciones generales
    handleObservacionGeneralCamaraChange = (e) => {
        this.setState({ observacionGeneralCamara: e.target.value });
    };

    // Handler para cambio en las observaciones del sistema de alarma
    handleObservacionGeneralAlarmaChange = (e) => {
        this.setState({ observacionGeneralAlarma: e.target.value });
    };

    // Handler para cambio en las observaciones del sistema de control de acceso
    handleObservacionGeneralControlAccesoChange = (e) => {
        this.setState({ observacionGeneralControlAcceso: e.target.value });
    };

    // Handler para cambio en las observaciones del sistema contra incendio
    handleObservacionGeneralContraIncendioChange = (e) => {
        this.setState({ observacionGeneralContraIncendio: e.target.value });
    };

    // -----------------------

    // Handler para cambiar el estado de Discador
    handleDiscadorChange = (index, e) => {
        const { value } = e.target;
        this.setState((prevState) => {
            const llamadaConsolaSeguridad = [...prevState.llamadaConsolaSeguridad];
            llamadaConsolaSeguridad[index].discador.estado = value;
            return { llamadaConsolaSeguridad };
        });
    };

    // Handler para cambiar el estado de RPS
    handleRPSChange = (index, e) => {
        const { value } = e.target;
        this.setState((prevState) => {
            const llamadaConsolaSeguridad = [...prevState.llamadaConsolaSeguridad];
            llamadaConsolaSeguridad[index].rps.estado = value;
            return { llamadaConsolaSeguridad };
        });
    };

    // Handler para cambiar el estado de CCTV
    handleCCTVChange = (index, e) => {
        const { value } = e.target;
        this.setState((prevState) => {
            const llamadaConsolaSeguridad = [...prevState.llamadaConsolaSeguridad];
            llamadaConsolaSeguridad[index].cctv.estado = value;
            return { llamadaConsolaSeguridad };
        });
    };

    // Handler para cambiar el estado de Audio
    handleAudioChange = (index, e) => {
        const { value } = e.target;
        this.setState((prevState) => {
            const llamadaConsolaSeguridad = [...prevState.llamadaConsolaSeguridad];
            llamadaConsolaSeguridad[index].audio.estado = value;
            return { llamadaConsolaSeguridad };
        });
    };

    // Handler para cambiar la consola
    handleConsolaChange = (index, e) => {
        const { value } = e.target;
        this.setState((prevState) => {
            const llamadaConsolaSeguridad = [...prevState.llamadaConsolaSeguridad];
            llamadaConsolaSeguridad[index].consola = value;
            return { llamadaConsolaSeguridad };
        });
    };

    // Handler para cambiar el operador
    handleOperadorChange = (index, e) => {
        const { value } = e.target;
        this.setState((prevState) => {
            const llamadaConsolaSeguridad = [...prevState.llamadaConsolaSeguridad];
            llamadaConsolaSeguridad[index].operador = value;
            return { llamadaConsolaSeguridad };
        });
    };

    // Handler para cambiar las observaciones de telefonia
    handleObservacionesTelefoniaChange = (index, e) => {
        const { value } = e.target;
        this.setState((prevState) => {
            const llamadaConsolaSeguridad = [...prevState.llamadaConsolaSeguridad];
            llamadaConsolaSeguridad[index].observacionesTelefonia = value;
            return { llamadaConsolaSeguridad };
        });
    };

    //----------------

    // Handler para Observaciones
    handleObservacionesChange = (e) => {
        this.setState({ observaciones: e.target.value });
    }

    // Handler para DT15V6 Nombre y Apellido
    handleDt15v6NombreApellidoChange = (e) => {
        this.setState({ dt15v6NombreApellido: e.target.value });
    }

    // Handler para DT15V6 Cargo
    handleDt15v6CargoChange = (e) => {
        this.setState({ dt15v6Cargo: e.target.value });
    }

    // Handler para Cliente Nombre y Apellido
    handleClienteNombreApellidoChange = (e) => {
        this.setState({ clienteNombreApellido: e.target.value });
    }

    // Handler para Cliente Cargo
    handleClienteCargoChange = (e) => {
        this.setState({ clienteCargo: e.target.value });
    }

    // Handler para cambiar la imagen de soporte fotográfico
    handleSoporteFotograficoChange = (e) => {
        if (e.target.files.length > 0) {
            const imagenes = Array.from(e.target.files);
            this.setState({ soporteFotografico: imagenes });
        }
    };

    // Handler para subir imágenes de soporte fotográfico al almacenamiento de Firebase
    uploadImagen = async (imagen) => {
        const storageRef = firebase.storage().ref();
        const imagenRef = storageRef.child(`soporteFotografico/${Date.now()}_${imagen.name}`);
        await imagenRef.put(imagen);
        return await imagenRef.getDownloadURL();
    };

    // Handler para manejar la subida de imágenes de soporte fotográfico
    handleUploadSoporteFotografico = async () => {
        const { soporteFotografico } = this.state;
        const soporteFotograficoUrls = await Promise.all(soporteFotografico.map(async (imagen) => {
            return await this.uploadImagen(imagen);
        }));
        console.log('URLs de soporte fotográfico subidas con éxito:', soporteFotograficoUrls);
        return soporteFotograficoUrls;
    };

    //------------------------------

    render() {
        const { seccionActual, enviadoExitosamente, sistemaCamara, sistemaAlarma, sistemaControlAcceso, sistemaContraIncendio, sistemaActual } = this.state;

        return (
            <div className="submit-form wrapper">

                {seccionActual === 1 && (
                    <div>
                        <h2 className="text-sm-left text-center mb-4">Mantenimiento - Sección {seccionActual}: Registro</h2>
                        <div className="form-group">
                            <label htmlFor="fechaMantenimiento">Fecha de Mantenimiento</label>
                            <DatePicker
                                selected={this.state.fechaMantenimiento}
                                onChange={this.onChangeFecha}
                                dateFormat="dd/MM/yyyy"
                            />
                            {this.state.fechaError && (
                                <div className="text-danger">{this.state.fechaError}</div>
                            )}
                        </div>

                        <div className="form-group">
                            <label htmlFor="horaMantenimiento">Hora de Mantenimiento</label>
                            <TimePicker
                                id="horaMantenimiento"
                                value={this.state.horaMantenimiento}
                                onChange={this.onChangeHora}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="agencia">Seleccionar Agencia</label>
                            <select
                                className="form-control"
                                id="agencia"
                                value={this.state.selectedAgencia}
                                onChange={this.onChangeAgencia}
                            >
                                <option value="">Seleccionar una Agencia</option>
                                {this.state.agencias.map((agencia) => (
                                    <option key={agencia.id} value={agencia.id}>
                                        {agencia.numAgencia}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="numeroAgencia">Número de Agencia</label>
                            <input
                                type="text"
                                className="form-control"
                                id="numeroAgencia"
                                required
                                value={this.state.numAgencia}
                                onChange={this.onChangeNumero}
                                name="numeroAgencia"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="estadoAgencia">Estado de Agencia</label>
                            <input
                                type="text"
                                className="form-control"
                                id="estadoAgencia"
                                required
                                value={this.state.estadoAgencia}
                                onChange={this.onChangeEstado}
                                name="estadoAgencia"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="ubiAgencia">Ubicación de Agencia</label>
                            <input
                                type="text"
                                className="form-control"
                                id="ubiAgencia"
                                required
                                value={this.state.ubiAgencia}
                                onChange={this.onChangeUbicacion}
                                name="ubiAgencia"
                            />
                        </div>

                        <div className="form-group">
                            <label>Tipo de Control</label>
                            <div>
                                <label>
                                    <input
                                        type="radio"
                                        name="tipoMantenimiento"
                                        value="Preventivo"
                                        checked={this.state.tipoMantenimiento === "Preventivo"}
                                        onChange={this.onChangeTipoMantenimiento}
                                    /> Preventivo
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="tipoMantenimiento"
                                        value="Correctivo"
                                        checked={this.state.tipoMantenimiento === "Correctivo"}
                                        onChange={this.onChangeTipoMantenimiento}
                                        className="ml-2"
                                    /> Correctivo
                                </label>
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="descripcionIncidencias">Descripción de Incidencias</label>
                            <textarea
                                className="form-control"
                                id="descripcionIncidencias"
                                value={this.state.descripcionIncidencias}
                                onChange={this.onChangeDescripcionIncidencias}
                                rows="4"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="trabajoRealizado">Trabajo Realizado</label>
                            <textarea
                                className="form-control"
                                id="trabajoRealizado"
                                value={this.state.trabajoRealizado}
                                onChange={this.onChangeTrabajoRealizado}
                                rows="4"
                            />
                        </div>
                    </div>
                )}

                {this.state.seccionActual === 2 && (
                    <div>
                        <h2 className="text-sm-left text-center mb-4">Mantenimiento - Sección {this.state.seccionActual}: Sistemas</h2>
                        <h3 className="text-sm-left text-center mb-4 system-title">Sistema de Cámaras</h3>

                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Cantidad</th>
                                    <th>Bueno</th>
                                    <th>Malo</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.sistemaCamara.map((equipo, equipoIndex) => (
                                    <tr key={equipoIndex}>
                                        <td>{equipo.tipo}</td>
                                        <td style={{ maxWidth: '30px' }}>
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={equipo.cantidad}
                                                onChange={(e) => this.handleCantidadChange(equipoIndex, e)}
                                            />
                                        </td>
                                        <td style={{ maxWidth: '30px' }}>
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={equipo.cantidadBueno}
                                                onChange={(e) => this.handleCantidadBuenoChange(equipoIndex, e)}
                                            />
                                        </td>
                                        <td style={{ maxWidth: '30px' }}>
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={equipo.cantidadMalo}
                                                onChange={(e) => this.handleCantidadMaloChange(equipoIndex, e)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div className="form-group">
                            <label htmlFor="observacion_general_camara">Observaciones</label>
                            <textarea
                                className="form-control"
                                id="observacion_general_camara"
                                value={this.state.observacionGeneralCamara}
                                onChange={this.handleObservacionGeneralCamaraChange}
                                rows={5}
                            />
                        </div>

                        <div>
                            <h3 className="text-sm-left text-center mb-4 system-title">Sistema Anti Robo</h3>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Cantidad</th>
                                        <th>Bueno</th>
                                        <th>Malo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.sistemaAlarma.map((equipo, equipoIndex) => (
                                        <tr key={equipoIndex}>
                                            <td>{equipo.tipo}</td>
                                            <td style={{ maxWidth: '30px' }}>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={equipo.cantidad}
                                                    onChange={(e) => this.handleCantidadChangeAlarma(equipoIndex, e)}
                                                />
                                            </td>
                                            <td style={{ maxWidth: '30px' }}>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={equipo.cantidadBueno}
                                                    onChange={(e) => this.handleCantidadBuenoChangeAlarma(equipoIndex, e)}
                                                />
                                            </td>
                                            <td style={{ maxWidth: '30px' }}>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={equipo.cantidadMalo}
                                                    onChange={(e) => this.handleCantidadMaloChangeAlarma(equipoIndex, e)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className="form-group">
                                <label htmlFor="observacion_general_alarma">Observaciones</label>
                                <textarea
                                    className="form-control"
                                    id="observacion_general_alarma"
                                    value={this.state.observacionGeneralAlarma}
                                    onChange={this.handleObservacionGeneralAlarmaChange}
                                    rows={5}
                                />
                            </div>
                        </div>

                        <div>
                            <h3 className="text-sm-left text-center mb-4 system-title">Sistema de Control de Acceso</h3>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Cantidad</th>
                                        <th>Bueno</th>
                                        <th>Malo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.sistemaControlAcceso.map((equipo, equipoIndex) => (
                                        <tr key={equipoIndex}>
                                            <td>{equipo.tipo}</td>
                                            <td style={{ maxWidth: '30px' }}>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={equipo.cantidad}
                                                    onChange={(e) => this.handleCantidadChangeControlAcceso(equipoIndex, e)}
                                                />
                                            </td>
                                            <td style={{ maxWidth: '30px' }}>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={equipo.cantidadBueno}
                                                    onChange={(e) => this.handleCantidadBuenoChangeControlAcceso(equipoIndex, e)}
                                                />
                                            </td>
                                            <td style={{ maxWidth: '30px' }}>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={equipo.cantidadMalo}
                                                    onChange={(e) => this.handleCantidadMaloChangeControlAcceso(equipoIndex, e)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className="form-group">
                                <label htmlFor="observacion_general_control_acceso">Observaciones</label>
                                <textarea
                                    className="form-control"
                                    id="observacion_general_control_acceso"
                                    value={this.state.observacionGeneralControlAcceso}
                                    onChange={this.handleObservacionGeneralControlAccesoChange}
                                    rows={5}
                                />
                            </div>
                        </div>

                        <div>
                            <h3 className="text-sm-left text-center mb-4 system-title">Sistema Contra Incendio</h3>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Cantidad</th>
                                        <th>Bueno</th>
                                        <th>Malo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.sistemaContraIncendio.map((equipo, equipoIndex) => (
                                        <tr key={equipoIndex}>
                                            <td>{equipo.tipo}</td>
                                            <td style={{ maxWidth: '30px' }}>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={equipo.cantidad}
                                                    onChange={(e) => this.handleCantidadChangeContraIncendio(equipoIndex, e)}
                                                />
                                            </td>
                                            <td style={{ maxWidth: '30px' }}>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={equipo.cantidadBueno}
                                                    onChange={(e) => this.handleCantidadBuenoChangeContraIncendio(equipoIndex, e)}
                                                />
                                            </td>
                                            <td style={{ maxWidth: '30px' }}>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={equipo.cantidadMalo}
                                                    onChange={(e) => this.handleCantidadMaloChangeContraIncendio(equipoIndex, e)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className="form-group">
                                <label htmlFor="observacion_general_contra_incendio">Observaciones</label>
                                <textarea
                                    className="form-control"
                                    id="observacion_general_contra_incendio"
                                    value={this.state.observacionGeneralContraIncendio}
                                    onChange={this.handleObservacionGeneralContraIncendioChange}
                                    rows={5}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {this.state.seccionActual === 3 && (
                    <div>
                        <h2 className="text-sm-left text-center mb-4">Mantenimiento - Sección {this.state.seccionActual}: Llamada a Consola de Seguridad</h2>
                        {this.state.llamadaConsolaSeguridad.map((llamada, index) => (
                            <div key={index}>
                                <div className="form-group">
                                    <label htmlFor={`consola_${index}`}>Consola</label>
                                    <select
                                        className="form-control"
                                        id={`consola_${index}`}
                                        value={llamada.consola || "default"}
                                        onChange={(e) => this.handleConsolaChange(index, e)}
                                    >
                                        <option value="default" disabled>Selecciona una consola</option>
                                        <option value="Caracas">Caracas</option>
                                        <option value="Maracaibo">Maracaibo</option>
                                        <option value="Maracay">Maracay</option>
                                        <option value="Valencia">Valencia</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor={`operador_${index}`}>Operador</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id={`operador_${index}`}
                                        value={llamada.operador}
                                        onChange={(e) => this.handleOperadorChange(index, e)}
                                    />
                                </div>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Equipo</th>
                                            <th>Estado</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Discador</td>
                                            <td>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name={`discador_${index}`}
                                                            value="Positivo"
                                                            checked={llamada.discador.estado === "Positivo"}
                                                            onChange={(e) => this.handleDiscadorChange(index, e)}
                                                        /> Positivo
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name={`discador_${index}`}
                                                            value="Negativo"
                                                            checked={llamada.discador.estado === "Negativo"}
                                                            onChange={(e) => this.handleDiscadorChange(index, e)}
                                                        /> Negativo
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>RPS</td>
                                            <td>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name={`rps_${index}`}
                                                            value="Positivo"
                                                            checked={llamada.rps.estado === "Positivo"}
                                                            onChange={(e) => this.handleRPSChange(index, e)}
                                                        /> Positivo
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name={`rps_${index}`}
                                                            value="Negativo"
                                                            checked={llamada.rps.estado === "Negativo"}
                                                            onChange={(e) => this.handleRPSChange(index, e)}
                                                        /> Negativo
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>CCTV</td>
                                            <td>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name={`cctv_${index}`}
                                                            value="Positivo"
                                                            checked={llamada.cctv.estado === "Positivo"}
                                                            onChange={(e) => this.handleCCTVChange(index, e)}
                                                        /> Positivo
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name={`cctv_${index}`}
                                                            value="Negativo"
                                                            checked={llamada.cctv.estado === "Negativo"}
                                                            onChange={(e) => this.handleCCTVChange(index, e)}
                                                        /> Negativo
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Audio</td>
                                            <td>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name={`audio_${index}`}
                                                            value="Positivo"
                                                            checked={llamada.audio.estado === "Positivo"}
                                                            onChange={(e) => this.handleAudioChange(index, e)}
                                                        /> Positivo
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name={`audio_${index}`}
                                                            value="Negativo"
                                                            checked={llamada.audio.estado === "Negativo"}
                                                            onChange={(e) => this.handleAudioChange(index, e)}
                                                        /> Negativo
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="form-group">
                                    <label htmlFor={`observaciones_${index}`}>Observaciones Telefonía</label>
                                    <textarea
                                        className="form-control"
                                        id={`observaciones_${index}`}
                                        value={llamada.observacionesTelefonia}
                                        onChange={(e) => this.handleObservacionesTelefoniaChange(index, e)}
                                        rows={5}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {this.state.seccionActual === 4 && (
                    <div>
                        <h2 className="text-sm-left text-center mb-4">Mantenimiento - Sección {seccionActual}: Observaciones</h2>

                        <label htmlFor="observaciones">Observaciones</label>
                        <textarea
                            className="form-control mb-3"
                            id="observaciones"
                            value={this.state.observaciones}
                            onChange={this.handleObservacionesChange}
                            rows="4"
                        ></textarea>

                        <h3 className="mt-4 mb-3">Por DT15V6</h3>

                        <div className="form-group">
                            <label htmlFor="dt15v6NombreApellido">Nombre y Apellido</label>
                            <input
                                type="text"
                                className="form-control"
                                id="dt15v6NombreApellido"
                                value={this.state.dt15v6NombreApellido}
                                onChange={this.handleDt15v6NombreApellidoChange}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="dt15v6Cargo">Cargo</label>
                            <input
                                type="text"
                                className="form-control"
                                id="dt15v6Cargo"
                                value={this.state.dt15v6Cargo}
                                onChange={this.handleDt15v6CargoChange}
                            />
                        </div>

                        <h3 className="mt-4 mb-3">Por el Cliente</h3>

                        <div className="form-group">
                            <label htmlFor="clienteNombreApellido">Nombre y Apellido</label>
                            <input
                                type="text"
                                className="form-control"
                                id="clienteNombreApellido"
                                value={this.state.clienteNombreApellido}
                                onChange={this.handleClienteNombreApellidoChange}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="clienteCargo">Cargo</label>
                            <input
                                type="text"
                                className="form-control"
                                id="clienteCargo"
                                value={this.state.clienteCargo}
                                onChange={this.handleClienteCargoChange}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="soporteFotografico">Soporte Fotográfico</label>
                            <input
                                type="file"
                                accept="image/*"
                                className="form-control-file"
                                id="soporteFotografico"
                                onChange={(e) => this.handleSoporteFotograficoChange(e)}
                                multiple // Permite la selección de múltiples archivos
                            />
                            {this.state.soporteFotografico && this.state.soporteFotografico.map((imagen, index) => (
                                <div key={index}>
                                    <img src={URL.createObjectURL(imagen)} alt={`Soporte Fotográfico ${index}`} style={{ maxWidth: '200px', marginRight: '10px' }} />
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <div>
                    {seccionActual > 1 && !enviadoExitosamente && (
                        <button onClick={this.handleAtras} className="btn btn-primary mr-3 mb-5">
                            Atrás
                        </button>
                    )}

                    {seccionActual < 4 && !enviadoExitosamente && (
                        <button onClick={this.handleSiguiente} className="btn btn-primary mb-5">
                            Siguiente
                        </button>
                    )}

                    {seccionActual === 4 && !enviadoExitosamente && (
                        <button onClick={this.saveMantenimiento} className="btn btn-success mb-5">
                            Enviar
                        </button>
                    )}
                </div>

                {enviadoExitosamente && (
                    <div>
                        <h4>¡Enviado exitosamente!</h4>
                    </div>
                )}

            </div>
        );
    }
}
