import React, { Component } from "react";
import { Switch, Route, Link, Redirect } from "react-router-dom";
import firebase from 'firebase/app';
import 'firebase/auth';
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'jquery/dist/jquery.slim';
import "@fontsource/poppins";
import '@fontsource/ubuntu';
import "./App.css";

import Login from "./components/login.component";

import AddAgencia from "./components/add-agencias.component";
import AgenciasList from "./components/agencias-list.component";

import MantenimientoList from "./components/mantenimientos-list.component";

import AddMantenimiento from "./components/add-mantenimientos.component";

import DetalleMantenimiento from './components/detalle-mantenimiento.component';

class App extends Component {

  constructor() {
    super();
    this.state = {
      user: null,
      userRole: null,
    };
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        const db = firebase.firestore();
        const userRef = db.collection('usuarios').doc(user.uid);

        userRef.get().then(doc => {
          if (doc.exists) {
            const userData = doc.data();
            const userRole = userData.rol;

            this.setState({ user, userRole });
            console.log("Usuario autenticado:", user);
            console.log("Rol del usuario:", userRole);

            this.handleRoleSpecificActions(userRole);
          } else {
            this.setState({ user, userRole: null });
          }
        });
      } else {
        this.setState({ user: null, userRole: null });
      }
    });
  }

  handleRoleSpecificActions = (userRole) => {
    // Lógica para manejar acciones específicas según el rol
    if (userRole === "admin") {
      console.log("Acciones para el rol de administrador");
      // Puedes redirigir o actualizar la interfaz de usuario aquí
    } else if (userRole === "tecnico") {
      console.log("Acciones para el rol de técnico");
      // Puedes redirigir o actualizar la interfaz de usuario aquí
    } else if (userRole === "supervisor") {
      console.log("Acciones para el rol de supervisor");
      // Puedes redirigir o actualizar la interfaz de usuario aquí
    }
    // Agrega más casos según sea necesario
  };

  handleLogout = () => {
    firebase.auth().signOut().then(() => {
      console.log("Se cerró sesión exitosamente");
    }).catch((error) => {
      console.error("Error al cerrar sesión:", error);
    });
  };

  render() {
    const { user, userRole } = this.state;

    return (
      <div>
        {user ? (
          <div>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
              <a href="/" className="navbar-brand">
                mttodt15v6
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <div className="navbar-nav">
                  {userRole === "admin" && (
                    <>
                      <Link to={"/agencias"} className="nav-link">
                        Agencias
                      </Link>
                      <Link to={"/agregarAgencia"} className="nav-link">
                        Agregar agencia
                      </Link>
                      <Link to={"/mantenimientos"} className="nav-link">
                        Mantenimientos
                      </Link>
                      <Link to={"/agregarMantenimiento"} className="nav-link">
                        Agregar mantenimiento
                      </Link>
                    </>
                  )}

                  {userRole === "tecnico" && (
                    <Link to={"/agregarMantenimiento"} className="nav-link">
                      Agregar mantenimiento
                    </Link>
                  )}

                  {userRole === "supervisor" && (
                    <Link to={"/mantenimientos"} className="nav-link">
                      Mantenimientos
                    </Link>
                  )}
                </div>
                <div className="navbar-nav ml-auto">
                  <button className="nav-link btn btn-link text-left" onClick={this.handleLogout}>
                    Cerrar Sesión
                  </button>
                </div>
              </div>
            </nav>

            <div className="container mt-4">
              <Switch>
                {userRole === "admin" && (
                  <>
                    <Route exact path={["/", "/agencias"]} component={AgenciasList} />
                    <Route exact path="/agregarAgencia" component={AddAgencia} />
                    <Route exact path="/mantenimientos" component={MantenimientoList} />
                    <Route exact path="/agregarMantenimiento" component={AddMantenimiento} />
                    <Route
                      path="/detalleMantenimiento/:id"
                      render={(props) => (
                        <DetalleMantenimiento {...props} userRole={userRole} />
                      )}
                    />
                  </>
                )}

                {userRole === "tecnico" && (
                  <Route exact path={["/", "/agregarMantenimiento"]} component={AddMantenimiento} />
                )}

                {userRole === "supervisor" && (
                  <>
                    <Route exact path={["/", "/mantenimientos"]} component={MantenimientoList} />
                    <Route path="/detalleMantenimiento/:id" component={DetalleMantenimiento} />
                  </>
                )}

                {/* Otras rutas comunes para todas las personas autenticadas */}
                {/*<Route path="/otraRuta" component={OtraRutaComponent} />*/}

                {/* Ruta predeterminada en caso de que ninguna otra coincida */}
                {/*<Route component={PaginaNoEncontrada} />*/}
              </Switch>
            </div>
          </div>
        ) : (
          <Login />
        )}
      </div>
    );
  }
}

export default App;
