import React, { Component } from "react";
import AgenciasDataService from "../services/agencias.service";

import Agencia from "./agencia.component";

export default class AgenciasList extends Component {
    constructor(props) {
        super(props);
        this.refreshList = this.refreshList.bind(this);
        this.onDataChange = this.onDataChange.bind(this);

        this.state = {
            agencias: [],
            currentAgencia: null,
            currentIndex: -1,
        };

        this.unsubscribe = undefined;
    }

    componentDidMount() {
        this.unsubscribe = AgenciasDataService.getAll().orderBy("numAgencia", "asc").onSnapshot(this.onDataChange);
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    onDataChange(items) {
        let agencias = [];

        items.forEach((item) => {
            let id = item.id;
            let data = item.data();
            agencias.push({
                id: id,
                // nombreAgencia: data.nombreAgencia,
                numAgencia: data.numAgencia,
                estadoAgencia: data.estadoAgencia,
                ubiAgencia: data.ubiAgencia,
            });
        });

        this.setState({
            agencias: agencias,
        });
    }

    refreshList() {
        this.setState({
            currentAgencia: null,
            currentIndex: -1,
        });
    }

    setActiveAgencia(agencia, index) {
        this.setState({
            currentAgencia: agencia,
            currentIndex: index,
        });
    }

    render() {
        const { agencias, currentAgencia, currentIndex } = this.state;

        return (
            <div className="list row wrapper">
                <div className="col-md-6">
                    <h4 className="text-sm-left text-center">Lista de agencias</h4>
                    <ul className="list-group">
                        {agencias &&
                            agencias.map((agencia, index) => (
                                <li
                                    className={
                                        "list-group-item " +
                                        (index === currentIndex ? "active" : "")
                                    }
                                    onClick={() => this.setActiveAgencia(agencia, index)}
                                    key={index}
                                >
                                    {agencia.numAgencia}
                                </li>
                            ))}
                    </ul>
                </div>
                <div className="col-md-6">
                    {currentAgencia ? (
                        <Agencia
                            agencia={currentAgencia}
                            refreshList={this.refreshList}
                        />
                    ) : (
                        <div>
                            <br />
                            <p>Pulsa para examinar agencia...</p>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
