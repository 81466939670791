    import React, { Component } from "react";
    import AgenciasDataService from "../services/agencias.service";

    export default class AddAgencia extends Component {
        constructor(props) {
            super(props);
            this.onChangeNombre = this.onChangeNombre.bind(this);
            this.onChangeNumero = this.onChangeNumero.bind(this);
            this.onChangeEstado = this.onChangeEstado.bind(this);
            this.onChangeUbicacion = this.onChangeUbicacion.bind(this);
            this.saveAgencia = this.saveAgencia.bind(this);
            this.newAgencia = this.newAgencia.bind(this);

            this.state = {
                // nombreAgencia: "",
                numAgencia: "",
                estadoAgencia: "",
                ubiAgencia: "",
                submitted: false,
            };
        }

        onChangeNombre(e) {
            this.setState({
                // nombreAgencia: e.target.value,
            });
        }

        onChangeNumero(e) {
            this.setState({
                numAgencia: e.target.value,
            });
        }

        onChangeUbicacion(e) {
            this.setState({
                ubiAgencia: e.target.value,
            });
        }

        onChangeEstado(e) {
            this.setState({
                estadoAgencia: e.target.value,
            });
        }

        saveAgencia() {
            let data = {
                // nombreAgencia: this.state.nombreAgencia,
                numAgencia: this.state.numAgencia,
                estadoAgencia: this.state.estadoAgencia,
                ubiAgencia: this.state.ubiAgencia
            };

            AgenciasDataService.create(data)
                .then(() => {
                    console.log("Nueva agencia creada con éxito!");
                    this.setState({
                        submitted: true,
                    });
                })
                .catch((e) => {
                    console.log(e);
                });
        }

        newAgencia() {
            this.setState({
                // nombreAgencia: "",
                numAgencia: "",
                estadoAgencia: "",
                ubiAgencia: "",
                submitted: false,
            });
        }

        render() {
            return (
                <div className="submit-form wrapper">
                    {this.state.submitted ? (
                        <div>
                            <h4>¡Enviado exitosamente!</h4>
                            <button className="btn btn-success" onClick={this.newAgencia}>
                                Agregar
                            </button>
                        </div>
                    ) : (
                        <div>
                            <h4 className="text-sm-left text-center mb-4">Agregar agencia</h4>
                            {/* <div className="form-group">
                                <label htmlFor="nombreAgencia">Nombre de Agencia</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="nombreAgencia"
                                    required
                                    value={this.state.nombreAgencia}
                                    onChange={this.onChangeNombre}
                                    name="nombreAgencia"
                                />
                            </div> */}

                            <div className="form-group">
                                <label htmlFor="numeroAgencia">Número de Agencia</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="numeroAgencia"
                                    required
                                    value={this.state.numeroAgencia}
                                    onChange={this.onChangeNumero}
                                    name="numeroAgencia"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="estadoAgencia">Estado de Agencia</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="estadoAgencia"
                                    required
                                    value={this.state.estadoAgencia}
                                    onChange={this.onChangeEstado}
                                    name="estadoAgencia"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="ubiAgencia">Ubicación de Agencia</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="ubiAgencia"
                                    required
                                    value={this.state.ubiAgencia}
                                    onChange={this.onChangeUbicacion}
                                    name="ubiAgencia"
                                />
                            </div>
                            <button onClick={this.saveAgencia} className="btn btn-success">
                                Enviar
                            </button>
                        </div>
                    )}
                </div>
            );
        }
    }
