import React, { Component } from "react";
import { Link } from "react-router-dom";
import ExcelJS from "exceljs";
import firebase from 'firebase/app';
import 'firebase/firestore';
import DetalleMantenimiento from './detalle-mantenimiento.component'; // Asegúrate de importar el componente DetalleMantenimiento correctamente

// Agrega la función para formatear la hora aquí
const formatHour = (hour) => {
    const [hourStr, minuteStr] = hour.split(':');
    const hourInt = parseInt(hourStr, 10);
    const minuteInt = parseInt(minuteStr, 10);
    const ampm = hourInt >= 12 ? 'PM' : 'AM';
    let formattedHour = (hourInt % 12 || 12).toString();
    formattedHour += `:${minuteInt < 10 ? '0' : ''}${minuteInt} ${ampm}`;
    return formattedHour;
};

class DetalleAgencia extends Component {
    constructor() {
        super();
        this.state = {
            agencia: null,
            mantenimientosDeAgencia: [],
            mostrarListaMantenimientos: false,
            mantenimientoSeleccionado: null,
        };
    }

    componentDidUpdate(prevProps) {
        const { agenciaId } = this.props;
        if (agenciaId !== prevProps.agenciaId) {
            this.fetchDataForAgencia(agenciaId);
        }
    }

    fetchDataForAgencia = async (agenciaId) => {
        if (!agenciaId) {
            console.error("ID de agencia no definido.");
            return;
        }

        const db = firebase.firestore();

        try {
            const agenciaDoc = await db.collection('agencias').doc(agenciaId).get();
            const agencia = agenciaDoc.data();

            const mantenimientosSnapshot = await db.collection('mantenimientos').where('numAgencia', '==', agencia.numAgencia).get();
            const mantenimientosDeAgencia = mantenimientosSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            this.setState({ agencia, mantenimientosDeAgencia });
        } catch (error) {
            console.error("Error al obtener datos de la agencia:", error);
        }
    }

    handleDescargarReporte = async () => {
        const { mantenimientosDeAgencia, agencia } = this.state;

        if (!agencia) {
            console.error("La agencia no está definida.");
            return;
        }

        // Verifica si hay mantenimientos antes de continuar
        if (mantenimientosDeAgencia.length === 0) {
            console.warn("No hay mantenimientos para descargar.");
            return;
        }

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Mantenimientos");

        // Agregar encabezados
        worksheet.columns = [
            // ... Otros encabezados
            { header: "Fecha", key: "fechaMantenimiento", width: 15 },
            { header: "Hora", key: "horaMantenimiento", width: 15 },
            // { header: "Nombre Agencia", key: "nombreAgencia", width: 30 },
            { header: "Número Agencia", key: "numAgencia", width: 15 },
            { header: "Ubicación Agencia", key: "ubiAgencia", width: 30 },
            { header: "Tipo de Mantenimiento", key: "tipoMantenimiento", width: 30 },
            { header: "Descripcion de Incidencias", key: "descripcionIncidencias", width: 30 },
            { header: "Trabajo Realizado", key: "trabajoRealizado", width: 30 },
            // Recursos Necesarios
            // { header: "Mano de Obra", key: "manoDeObra", width: 30 },
            // { header: "Materiales y Repuestos", key: "materialesRepuestos", width: 30 },
            // { header: "Equipos Necesarios", key: "equiposNecesarios", width: 30 },
            // Tiempo de Ejecución
            // { header: "Tiempo de Ejecución", key: "tiempoEjecucion", width: 20 },
            // Sistema de Cámaras
            { header: "Sistema de Cámaras", key: "sistemaCamara", width: 50 },
            // Sistema de Alarma
            { header: "Sistema de Alarma", key: "sistemaAlarma", width: 50 },
            // Sistema de Control de Acceso
            { header: "Control de Acceso", key: "sistemaControlAcceso", width: 50 },
            // Sistema Contra Incendio
            { header: "Sistema Contra Incendio", key: "sistemaContraIncendio", width: 50 },
            // Otros Sistemas
            // { header: "Otros Sistemas", key: "sistemaOtros", width: 50 },
            // Llamada a Consola de Seguridad
            { header: "Llamada a Consola de Seguridad", key: "llamadaConsolaSeguridad", width: 50 },
            // Recomendaciones
            // { header: "Recomendaciones", key: "recomendaciones", width: 30 },
            // Observaciones
            { header: 'Observaciones', key: 'observaciones', width: 30 },
            // DT15V6
            { header: 'Nombre DT15V6', key: 'nombreDT15V6', width: 30 },
            { header: 'Cargo DT15V6', key: 'cargoDT15V6', width: 30 },
            // Cliente
            { header: 'Nombre Cliente', key: 'nombreCliente', width: 30 },
            { header: 'Cargo Cliente', key: 'cargoCliente', width: 30 },
            // Firmas
            // { header: "Firma Responsable Mantenimiento", key: "firmaResponsableMantenimiento", width: 30 },
            // { header: "Firma Responsable Agencia", key: "firmaResponsableAgencia", width: 30 },
            // ... Otros encabezados
        ];

        // Agregar datos
        mantenimientosDeAgencia.forEach((mantenimiento) => {
            const fechaFormateada = mantenimiento.fechaMantenimiento.toDate().toLocaleDateString();
            const horaFormateada = formatHour(mantenimiento.horaMantenimiento); // Formatear la hora
            // Procesar Sistema de Cámaras
            const sistemaCamaraData = mantenimiento.sistemaCamara
                .map((equipo) => {
                    const { tipo, cantidad, cantidadBueno, cantidadMalo } = equipo;
                    return `${tipo}: Cantidad: ${cantidad}, Bueno: ${cantidadBueno}, Malo: ${cantidadMalo}`;
                })
                .join('; ');

            // Procesar Sistema de Alarma
            const sistemaAlarmaData = mantenimiento.sistemaAlarma
                .map((equipo) => {
                    const { tipo, cantidad, cantidadBueno, cantidadMalo } = equipo;
                    return `${tipo}: Cantidad: ${cantidad}, Bueno: ${cantidadBueno}, Malo: ${cantidadMalo}`;
                })
                .join('; ');

            // Procesar Sistema de Control de Acceso
            const sistemaControlAccesoData = mantenimiento.sistemaControlAcceso
                .map((equipo) => {
                    const { tipo, cantidad, cantidadBueno, cantidadMalo } = equipo;
                    return `${tipo}: Cantidad: ${cantidad}, Bueno: ${cantidadBueno}, Malo: ${cantidadMalo}`;
                })
                .join('; ');

            // Procesar Sistema Contra Incendio
            const sistemaContraIncendioData = mantenimiento.sistemaContraIncendio
                .map((equipo) => {
                    const { tipo, cantidad, cantidadBueno, cantidadMalo } = equipo;
                    return `${tipo}: Cantidad: ${cantidad}, Bueno: ${cantidadBueno}, Malo: ${cantidadMalo}`;
                })
                .join('; ');


            // // Procesar Otros Sistemas
            // const sistemaOtrosData = mantenimiento.sistemaOtros
            //     .filter((equipo) => equipo.cantidad > 0)
            //     .map((equipo) => `${equipo.tipo}: Cantidad: ${equipo.cantidad}, Estado: ${equipo.estado}`)
            //     .join('; ');

            // Procesar Llamado a Consola de Seguridad
            const llamadaConsolaSeguridadData = mantenimiento.llamadaConsolaSeguridad.map((llamada) => `Cargo: ${llamada.cargo}, Resultado: ${llamada.resultado}, Observaciones: ${llamada.observaciones}`).join('; ');

            worksheet.addRow({
                fechaMantenimiento: fechaFormateada,
                horaMantenimiento: horaFormateada,
                // nombreAgencia: mantenimiento.nombreAgencia,
                numAgencia: mantenimiento.numAgencia,
                ubiAgencia: mantenimiento.ubiAgencia,
                tipoMantenimiento: mantenimiento.tipoMantenimiento,
                descripcionIncidencias: mantenimiento.descripcionIncidencias,
                trabajoRealizado: mantenimiento.trabajoRealizado,
                // Recursos Necesarios
                // manoDeObra: mantenimiento.recursosNecesarios.manoDeObra.map((mo) => `${mo.cantidad} - ${mo.descripcion}`).join(', '),
                // materialesRepuestos: mantenimiento.recursosNecesarios.materialesRepuestos.map((material) => `${material.cantidad} - ${material.descripcion}`).join(', '),
                // equiposNecesarios: mantenimiento.recursosNecesarios.equiposNecesarios.map((equipo) => `${equipo.cantidad} - ${equipo.descripcion}`).join(', '),
                // Tiempo de Ejecución
                tiempoEjecucion: mantenimiento.tiempoEjecucion,
                // Sistemas
                sistemaCamara: sistemaCamaraData,
                sistemaAlarma: sistemaAlarmaData,
                sistemaControlAcceso: sistemaControlAccesoData,
                sistemaContraIncendio: sistemaContraIncendioData,
                // sistemaOtros: sistemaOtrosData,
                // Llamada a Consola de Seguridad
                llamadaConsolaSeguridad: llamadaConsolaSeguridadData,
                // Recomendaciones
                // recomendaciones: mantenimiento.recomendaciones,
                // Observaciones
                observaciones: mantenimiento.observaciones,
                // DT15V6
                nombreDT15V6: mantenimiento.dt15v6.nombreApellido,
                cargoDT15V6: mantenimiento.dt15v6.cargo,
                // Cliente
                nombreCliente: mantenimiento.cliente.nombreApellido,
                cargoCliente: mantenimiento.cliente.cargo,
                // Firmas
                // firmaResponsableMantenimiento: mantenimiento.firmaResponsableMantenimiento,
                // firmaResponsableAgencia: mantenimiento.firmaResponsableAgencia,
                // ... Otros datos
            });
        });

        // Convertir el workbook a blob
        const blob = await workbook.xlsx.writeBuffer().then((buffer) => new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }));

        // Crear un objeto URL para el blob
        const url = URL.createObjectURL(blob);

        // Crear un enlace (link) para descargar el archivo
        const link = document.createElement("a");
        link.href = url;
        link.download = `reporte_mantenimientos_${agencia.numAgencia || "sin_nombre"}.xlsx`;

        // Simular un clic en el enlace para iniciar la descarga
        link.click();

        // Liberar el objeto URL
        URL.revokeObjectURL(url);
    };

    handleMostrarListaMantenimientos = () => {
        this.setState({ mostrarListaMantenimientos: true, mantenimientoSeleccionado: null });
    };

    handleSeleccionarMantenimiento = (mantenimiento) => {
        this.setState({ mostrarListaMantenimientos: false, mantenimientoSeleccionado: mantenimiento });
    };

    render() {
        const { agencia, mantenimientosDeAgencia } = this.state;

        if (!agencia) {
            return <p>Cargando detalles de la agencia...</p>;
        }

        return (
            <div>
                <h4>Detalles de la Agencia</h4>
                <p>Nombre: {agencia.numAgencia}</p>
                <p>Total de Mantenimientos: {mantenimientosDeAgencia.length}</p>

                {/* Mostrar la lista de mantenimientos */}
                {mantenimientosDeAgencia.length > 0 && (
                    <ul>
                        {mantenimientosDeAgencia.map((mantenimiento) => (
                            <li key={mantenimiento.id}>
                                <Link to={`/detalleMantenimiento/${mantenimiento.id}`}>
                                    {mantenimiento.fechaMantenimiento.toDate().toLocaleDateString()}
                                </Link>
                            </li>
                        ))}
                    </ul>
                )}

                {/* Agregar botón de descarga */}
                <button onClick={this.handleDescargarReporte} disabled={mantenimientosDeAgencia.length === 0} className="btn btn-primary btn-block">
                    Descargar Reporte
                </button>

                {/* Agregar cualquier otro detalle que desees mostrar */}
            </div>
        );
    }


}

export default DetalleAgencia;