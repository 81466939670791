import React, { useEffect, useState, useRef } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";

const DetalleMantenimiento = ({ match, userRole }) => {
    const [mantenimiento, setMantenimiento] = useState({});
    const [editedMantenimiento, setEditedMantenimiento] = useState({});
    const [editedFechaMantenimiento, setEditedFechaMantenimiento] = useState('');
    const [editedHoraMantenimiento, setEditedHoraMantenimiento] = useState('');
    const [observacionGeneralCamara, setObservacionGeneralCamara] = useState('');
    const [observacionGeneralAlarma, setObservacionGeneralAlarma] = useState('');
    const [observacionGeneralControlAcceso, setObservacionGeneralControlAcceso] = useState('');
    const [observacionGeneralContraIncendio, setObservacionGeneralContraIncendio] = useState('');
    const [isTextEditing, setIsTextEditing] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [agencias, setAgencias] = useState([]);

    const agenciaSelectRef = useRef(null);

    useEffect(() => {
        const fetchMantenimiento = async () => {
            const db = firebase.firestore();
            const mantenimientoRef = db.collection('mantenimientos').doc(match.params.id);

            try {
                const doc = await mantenimientoRef.get();
                if (doc.exists) {
                    const data = doc.data();
                    setMantenimiento(data);
                    setObservacionGeneralCamara(data.observacionGeneralCamara || '');
                    setObservacionGeneralAlarma(data.observacionGeneralAlarma || '');
                    setObservacionGeneralControlAcceso(data.observacionGeneralControlAcceso || '');
                    setObservacionGeneralContraIncendio(data.observacionGeneralContraIncendio || '');
                    setEditedMantenimiento(data);
                } else {
                    console.log('No se encontró el mantenimiento');
                }
            } catch (error) {
                console.error('Error al obtener el mantenimiento:', error);
            }
        };

        fetchMantenimiento();

        // Lógica para obtener las agencias, asumiendo que está en un servicio
        const fetchAgencias = async () => {
            const db = firebase.firestore();
            const agenciasRef = db.collection('agencias');

            try {
                const snapshot = await agenciasRef.get();
                const agenciasData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setAgencias(agenciasData);
            } catch (error) {
                console.error('Error al obtener las agencias:', error);
            }
        };

        fetchAgencias();

    }, [match.params.id]);

    const handleChange = (index, key, value, sistemaType) => {
        if (!editedMantenimiento[sistemaType]) {
            console.error(`${sistemaType} no está definido en editedMantenimiento.`);
            return;
        }

        const updatedSistema = editedMantenimiento[sistemaType].map((equipo, idx) => {
            if (idx === index) {
                return {
                    ...equipo,
                    [key]: value === '' ? 0 : parseInt(value),
                };
            }
            return equipo;
        });

        setEditedMantenimiento(prevState => ({
            ...prevState,
            [sistemaType]: updatedSistema,
        }));
    };

    const handleTextEditing = () => {
        setIsTextEditing(!isTextEditing);
    };

    const handleChangeText = (key, value) => {
        setEditedMantenimiento(prevState => ({
            ...prevState,
            [key]: value,
        }));
    };

    const handleChangeObservacionGeneralCamara = (value) => {
        setObservacionGeneralCamara(value);
    };

    const handleChangeObservacionGeneralAlarma = (value) => {
        setObservacionGeneralAlarma(value);
    };

    const handleChangeObservacionGeneralControlAcceso = (value) => {
        setObservacionGeneralControlAcceso(value);
    };

    const handleChangeObservacionGeneralContraIncendio = (value) => {
        setObservacionGeneralContraIncendio(value);
    };

    const handleChangeObservaciones = (e) => {
        const value = e.target.value || ''; // Si e.target.value es null o undefined, asignamos una cadena vacía
        setEditedMantenimiento(prevState => ({
            ...prevState,
            observaciones: value,
        }));
    };

    const handleSaveChanges = async () => {
        setIsEditing(false);

        const db = firebase.firestore();
        const mantenimientoRef = db.collection('mantenimientos').doc(match.params.id);

        try {
            // Obtenemos el mantenimiento original
            const doc = await mantenimientoRef.get();
            if (!doc.exists) {
                console.error('No se encontró el mantenimiento');
                return;
            }

            const originalMantenimiento = doc.data();

            // Creamos un nuevo objeto con los cambios
            const updatedMantenimiento = {
                ...originalMantenimiento, // Mantenemos los valores originales
                ...editedMantenimiento, // Agregamos los valores editados
                fechaMantenimiento: editedFechaMantenimiento || originalMantenimiento.fechaMantenimiento,
                horaMantenimiento: editedHoraMantenimiento || originalMantenimiento.horaMantenimiento,
                observacionGeneralCamara: observacionGeneralCamara || originalMantenimiento.observacionGeneralCamara,
                observacionGeneralAlarma: observacionGeneralAlarma || originalMantenimiento.observacionGeneralAlarma,
                observacionGeneralControlAcceso: observacionGeneralControlAcceso || originalMantenimiento.observacionGeneralControlAcceso,
                observacionGeneralContraIncendio: observacionGeneralContraIncendio || originalMantenimiento.observacionGeneralContraIncendio,
            };

            // Actualizamos el documento en Firestore
            await mantenimientoRef.update(updatedMantenimiento);

            // Actualizamos el estado local para reflejar los cambios
            console.log('Cambios guardados con éxito');
            setMantenimiento(updatedMantenimiento);
        } catch (error) {
            console.error('Error al guardar los cambios:', error);
        }
    };

    // Función para manejar cambios en la llamada a consola de seguridad
    const handleLlamadaChange = (llamadaIndex, key, value) => {
        const updatedLlamadas = editedMantenimiento.llamadaConsolaSeguridad.map((llamada, index) => {
            if (index === llamadaIndex) {
                return {
                    ...llamada,
                    [key]: value,
                };
            }
            return llamada;
        });

        setEditedMantenimiento(prevState => ({
            ...prevState,
            llamadaConsolaSeguridad: updatedLlamadas,
        }));
    };

    // Función para manejar cambios en el estado de los equipos de llamada
    const handleEquipoEstadoChange = (llamadaIndex, equipoKey, estadoKey, value) => {
        const updatedLlamadas = editedMantenimiento.llamadaConsolaSeguridad.map((llamada, index) => {
            if (index === llamadaIndex) {
                return {
                    ...llamada,
                    [equipoKey]: {
                        ...llamada[equipoKey],
                        [estadoKey]: value,
                    },
                };
            }
            return llamada;
        });

        setEditedMantenimiento(prevState => ({
            ...prevState,
            llamadaConsolaSeguridad: updatedLlamadas,
        }));
    };

    const handleDeleteMantenimiento = () => {
        if (window.confirm('¿Estás seguro de que quieres eliminar este mantenimiento?')) {
            const db = firebase.firestore();
            const mantenimientoRef = db.collection('mantenimientos').doc(match.params.id);

            mantenimientoRef.delete()
                .then(() => {
                    console.log('Mantenimiento eliminado con éxito');
                    // Puedes redirigir o realizar otras acciones después de eliminar
                })
                .catch((error) => {
                    console.error('Error al eliminar el mantenimiento:', error);
                });
        }
    };

    // Función para formatear la fecha
    const formattedDate = (date) => {
        if (!date) return "No disponible";

        // Si date ya es un objeto Date, simplemente lo formateamos
        if (date instanceof Date) {
            return date.toLocaleDateString();
        }

        // Si no es un objeto Date, tratamos de convertirlo
        if (date.toDate) {
            return date.toDate().toLocaleDateString();
        }

        // Si no podemos convertirlo, devolvemos el valor original
        return date;
    };

    // Función para formatear la hora
    const formattedHour = (time) => {
        if (!time) return "No disponible";
        return time;
    };

    const handleFechaMantenimientoChange = (date) => {
        setEditedFechaMantenimiento(date);
    };

    // Función para convertir la hora a formato de 12 horas (AM/PM)
    const formatHour = (hour) => {
        // Verificar si la hora es válida
        if (!hour) return "No disponible";

        // Dividir la hora en horas y minutos
        const [hourStr, minuteStr] = hour.split(':');

        // Convertir a números enteros
        const hourInt = parseInt(hourStr, 10);
        const minuteInt = parseInt(minuteStr, 10);

        // Verificar si es AM o PM
        const ampm = hourInt >= 12 ? 'PM' : 'AM';

        // Convertir la hora al formato de 12 horas
        let formattedHour = (hourInt % 12 || 12).toString(); // Convertir 0 a 12 para 12 AM
        formattedHour += `:${minuteInt < 10 ? '0' : ''}${minuteInt} ${ampm}`;

        return formattedHour;
    };

    return (
        <div className="list row wrapper">
            <div className="col-md-12">
                <h4>Detalles del Mantenimiento</h4>
                <div className="section">
                    <h5>Mantenimiento General</h5>
                    <div className="section">
                        {isEditing ? (
                            <div className="form-group">
                                <label htmlFor="fechaMantenimiento">Fecha de Mantenimiento</label>
                                <DatePicker
                                    selected={editedFechaMantenimiento}
                                    onChange={handleFechaMantenimientoChange}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                        ) : (
                            <p><strong>Fecha:</strong> {formattedDate(mantenimiento.fechaMantenimiento)}</p>
                        )}
                    </div>
                    <div className="section">
                        {isEditing ? (
                            <div className="form-group">
                                <label htmlFor="horaMantenimiento">Hora de Mantenimiento</label>
                                <TimePicker
                                    id="horaMantenimiento"
                                    value={editedHoraMantenimiento}
                                    onChange={(time) => setEditedHoraMantenimiento(time)}
                                />
                            </div>
                        ) : (
                            <p><strong>Hora:</strong> {mantenimiento.horaMantenimiento ? formatHour(mantenimiento.horaMantenimiento) : "No disponible"}</p>
                        )}
                    </div>

                    {/* Sección para mostrar/editar información de la agencia */}
                    <div className="section">
                        {isEditing ? (
                            <div>
                                <div className="form-group">
                                    <label htmlFor="agencia">Seleccionar Agencia</label>
                                    <select
                                        className="form-control"
                                        id="agencia"
                                        value={editedMantenimiento.agenciaId || ''}
                                        onChange={(e) => {
                                            const selectedAgenciaId = e.target.value;
                                            const selectedAgencia = agencias.find(agencia => agencia.id === selectedAgenciaId);

                                            if (selectedAgencia) {
                                                setEditedMantenimiento(prevState => ({
                                                    ...prevState,
                                                    agenciaId: selectedAgencia.id,
                                                    numAgencia: selectedAgencia.numAgencia || '',
                                                    estadoAgencia: selectedAgencia.estadoAgencia || '',
                                                    ubiAgencia: selectedAgencia.ubiAgencia || '',
                                                }));
                                            } else {
                                                setEditedMantenimiento(prevState => ({
                                                    ...prevState,
                                                    agenciaId: '',
                                                    numAgencia: '',
                                                    estadoAgencia: '',
                                                    ubiAgencia: '',
                                                }));
                                            }
                                        }}
                                    >
                                        <option value="">Seleccionar una Agencia</option>
                                        {agencias.map((agencia) => (
                                            <option key={agencia.id} value={agencia.id}>
                                                {agencia.numAgencia}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="numAgencia">Número de Agencia</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="numAgencia"
                                        value={editedMantenimiento.numAgencia || ''}
                                        onChange={(e) => setEditedMantenimiento(prevState => ({
                                            ...prevState,
                                            numAgencia: e.target.value,
                                        }))}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="estadoAgencia">Estado de Agencia</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="estadoAgencia"
                                        value={editedMantenimiento.estadoAgencia || ''}
                                        onChange={(e) => setEditedMantenimiento(prevState => ({
                                            ...prevState,
                                            estadoAgencia: e.target.value,
                                        }))}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="ubiAgencia">Ubicación de Agencia</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="ubiAgencia"
                                        value={editedMantenimiento.ubiAgencia || ''}
                                        onChange={(e) => setEditedMantenimiento(prevState => ({
                                            ...prevState,
                                            ubiAgencia: e.target.value,
                                        }))}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div>
                                <p><strong>Número de Agencia:</strong> {editedMantenimiento.numAgencia || 'No disponible'}</p>
                                <p><strong>Estado de Agencia:</strong> {editedMantenimiento.estadoAgencia || 'No disponible'}</p>
                                <p><strong>Ubicación de Agencia:</strong> {editedMantenimiento.ubiAgencia || 'No disponible'}</p>
                            </div>
                        )}
                    </div>

                    <div className="section">
                        {isEditing ? (
                            <div className="form-group">
                                <label htmlFor="descripcionIncidencias"><strong>Descripción de Incidencias</strong></label>
                                <textarea
                                    id="descripcionIncidencias"
                                    value={editedMantenimiento.descripcionIncidencias || ''}
                                    onChange={(e) => handleChangeText('descripcionIncidencias', e.target.value)}
                                    className="form-control"
                                    rows={5}
                                    disabled={!isEditing}
                                />
                            </div>
                        ) : (
                            <div>
                                <strong>Descripción de Incidencias:</strong>
                                <p>{mantenimiento.descripcionIncidencias}</p>
                            </div>
                        )}
                    </div>

                    <div className="section">
                        {isEditing ? (
                            <div className="form-group">
                                <label htmlFor="trabajoRealizado"><strong>Trabajo Realizado</strong></label>
                                <textarea
                                    id="trabajoRealizado"
                                    value={editedMantenimiento.trabajoRealizado || ''}
                                    onChange={(e) => handleChangeText('trabajoRealizado', e.target.value)}
                                    className="form-control"
                                    rows={5}
                                    disabled={!isEditing}
                                />
                            </div>
                        ) : (
                            <div>
                                <strong>Trabajo Realizado:</strong>
                                <p>{editedMantenimiento.trabajoRealizado || 'No disponible'}</p>
                            </div>
                        )}
                    </div>
                </div>

                <div className="section">
                    <h5>Sistema de Cámaras</h5>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Cantidad</th>
                                <th>Bueno</th>
                                <th>Malo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {editedMantenimiento.sistemaCamara && editedMantenimiento.sistemaCamara.map((equipo, index) => (
                                <tr key={index}>
                                    <td>{equipo.tipo}</td>
                                    <td>
                                        <input
                                            type="number"
                                            value={equipo.cantidad || 0}
                                            onChange={(e) => handleChange(index, 'cantidad', e.target.value, 'sistemaCamara')}
                                            className="form-control"
                                            disabled={!isEditing}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            value={equipo.cantidadBueno || 0}
                                            onChange={(e) => handleChange(index, 'cantidadBueno', e.target.value, 'sistemaCamara')}
                                            className="form-control"
                                            disabled={!isEditing}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            value={equipo.cantidadMalo || 0}
                                            onChange={(e) => handleChange(index, 'cantidadMalo', e.target.value, 'sistemaCamara')}
                                            className="form-control"
                                            disabled={!isEditing}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="form-group">
                        <label htmlFor="observacionGeneralCamara"><strong>Observaciones Generales:</strong></label>
                        {isEditing ? (
                            <textarea
                                id="observacionGeneralCamara"
                                value={observacionGeneralCamara || ''}
                                onChange={(e) => handleChangeObservacionGeneralCamara(e.target.value)}
                                className="form-control"
                                rows={5}
                                disabled={!isEditing}
                            />
                        ) : (
                            <div>
                                <p>{observacionGeneralCamara || 'No disponible'}</p>
                            </div>
                        )}
                    </div>
                </div>

                <div className="section">
                    <h5>Sistema de Alarma</h5>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Cantidad</th>
                                <th>Bueno</th>
                                <th>Malo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {editedMantenimiento.sistemaAlarma && editedMantenimiento.sistemaAlarma.map((equipo, index) => (
                                <tr key={index}>
                                    <td>{equipo.tipo}</td>
                                    <td>
                                        <input
                                            type="number"
                                            value={equipo.cantidad || 0}
                                            onChange={(e) => handleChange(index, 'cantidad', e.target.value, 'sistemaAlarma')}
                                            className="form-control"
                                            disabled={!isEditing}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            value={equipo.cantidadBueno || 0}
                                            onChange={(e) => handleChange(index, 'cantidadBueno', e.target.value, 'sistemaAlarma')}
                                            className="form-control"
                                            disabled={!isEditing}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            value={equipo.cantidadMalo || 0}
                                            onChange={(e) => handleChange(index, 'cantidadMalo', e.target.value, 'sistemaAlarma')}
                                            className="form-control"
                                            disabled={!isEditing}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="form-group">
                        <label htmlFor="observacionGeneralAlarma"><strong>Observaciones Generales:</strong></label>
                        {isEditing ? (
                            <textarea
                                id="observacionGeneralAlarma"
                                value={observacionGeneralAlarma || ''}
                                onChange={(e) => handleChangeObservacionGeneralAlarma(e.target.value)}
                                className="form-control"
                                rows={5}
                                disabled={!isEditing}
                            />
                        ) : (
                            <div>
                                <p>{observacionGeneralAlarma || 'No disponible'}</p>
                            </div>
                        )}
                    </div>
                </div>

                <div className="section">
                    <h5>Sistema de Control de Acceso</h5>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Cantidad</th>
                                <th>Bueno</th>
                                <th>Malo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {editedMantenimiento.sistemaControlAcceso && editedMantenimiento.sistemaControlAcceso.map((equipo, index) => (
                                <tr key={index}>
                                    <td>{equipo.tipo}</td>
                                    <td>
                                        <input
                                            type="number"
                                            value={equipo.cantidad || 0}
                                            onChange={(e) => handleChange(index, 'cantidad', e.target.value, 'sistemaControlAcceso')}
                                            className="form-control"
                                            disabled={!isEditing}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            value={equipo.cantidadBueno || 0}
                                            onChange={(e) => handleChange(index, 'cantidadBueno', e.target.value, 'sistemaControlAcceso')}
                                            className="form-control"
                                            disabled={!isEditing}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            value={equipo.cantidadMalo || 0}
                                            onChange={(e) => handleChange(index, 'cantidadMalo', e.target.value, 'sistemaControlAcceso')}
                                            className="form-control"
                                            disabled={!isEditing}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="form-group">
                        <label htmlFor="observacionGeneralControlAcceso"><strong>Observaciones Generales:</strong></label>
                        {isEditing ? (
                            <textarea
                                id="observacionGeneralControlAcceso"
                                value={observacionGeneralControlAcceso || ''}
                                onChange={(e) => handleChangeObservacionGeneralControlAcceso(e.target.value)}
                                className="form-control"
                                rows={5}
                                disabled={!isEditing}
                            />
                        ) : (
                            <div>
                                <p>{observacionGeneralControlAcceso || 'No disponible'}</p>
                            </div>
                        )}
                    </div>
                </div>

                <div className="section">
                    <h5>Sistema Contra Incendio</h5>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Cantidad</th>
                                <th>Bueno</th>
                                <th>Malo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {editedMantenimiento.sistemaContraIncendio && editedMantenimiento.sistemaContraIncendio.map((equipo, index) => (
                                <tr key={index}>
                                    <td>{equipo.tipo}</td>
                                    <td>
                                        <input
                                            type="number"
                                            value={equipo.cantidad || 0}
                                            onChange={(e) => handleChange(index, 'cantidad', e.target.value, 'sistemaContraIncendio')}
                                            className="form-control"
                                            disabled={!isEditing}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            value={equipo.cantidadBueno || 0}
                                            onChange={(e) => handleChange(index, 'cantidadBueno', e.target.value, 'sistemaContraIncendio')}
                                            className="form-control"
                                            disabled={!isEditing}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            value={equipo.cantidadMalo || 0}
                                            onChange={(e) => handleChange(index, 'cantidadMalo', e.target.value, 'sistemaContraIncendio')}
                                            className="form-control"
                                            disabled={!isEditing}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="form-group">
                        <label htmlFor="observacionGeneralContraIncendio"><strong>Observaciones Generales:</strong></label>
                        {isEditing ? (
                            <textarea
                                id="observacionGeneralContraIncendio"
                                value={observacionGeneralContraIncendio || ''}
                                onChange={(e) => handleChangeObservacionGeneralContraIncendio(e.target.value)}
                                className="form-control"
                                rows={5}
                                disabled={!isEditing}
                            />
                        ) : (
                            <div>
                                <p>{observacionGeneralContraIncendio || 'No disponible'}</p>
                            </div>
                        )}
                    </div>
                </div>

                <div className="section">
                    <h5>Llamada a Consola de Seguridad</h5>
                    {isEditing ? (
                        editedMantenimiento.llamadaConsolaSeguridad && editedMantenimiento.llamadaConsolaSeguridad.map((llamada, llamadaIndex) => (
                            <div key={llamadaIndex}>
                                <div className="mb-3">
                                    <label htmlFor={`consola_${llamadaIndex}`}><strong>Consola</strong></label>
                                    <select
                                        className="form-control"
                                        id={`consola_${llamadaIndex}`}
                                        value={llamada.consola || "default"}
                                        onChange={(e) => handleLlamadaChange(llamadaIndex, 'consola', e.target.value)}
                                    >
                                        <option value="default" disabled>Selecciona una consola</option>
                                        <option value="Caracas">Caracas</option>
                                        <option value="Maracaibo">Maracaibo</option>
                                        <option value="Maracay">Maracay</option>
                                        <option value="Valencia">Valencia</option>
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor={`operador_${llamadaIndex}`}><strong>Operador</strong></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id={`operador_${llamadaIndex}`}
                                        value={llamada.operador}
                                        onChange={(e) => handleLlamadaChange(llamadaIndex, 'operador', e.target.value)}
                                    />
                                </div>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Equipo</th>
                                            <th>Estado</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Discador</td>
                                            <td>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name={`discador_${llamadaIndex}`}
                                                        value="Positivo"
                                                        checked={llamada.discador.estado === "Positivo"}
                                                        onChange={(e) => handleEquipoEstadoChange(llamadaIndex, 'discador', 'estado', e.target.value)}
                                                    /> Positivo
                                                </label>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name={`discador_${llamadaIndex}`}
                                                        value="Negativo"
                                                        checked={llamada.discador.estado === "Negativo"}
                                                        onChange={(e) => handleEquipoEstadoChange(llamadaIndex, 'discador', 'estado', e.target.value)}
                                                    /> Negativo
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>RPS</td>
                                            <td>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name={`rps_${llamadaIndex}`}
                                                        value="Positivo"
                                                        checked={llamada.rps.estado === "Positivo"}
                                                        onChange={(e) => handleEquipoEstadoChange(llamadaIndex, 'rps', 'estado', e.target.value)}
                                                    /> Positivo
                                                </label>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name={`rps_${llamadaIndex}`}
                                                        value="Negativo"
                                                        checked={llamada.rps.estado === "Negativo"}
                                                        onChange={(e) => handleEquipoEstadoChange(llamadaIndex, 'rps', 'estado', e.target.value)}
                                                    /> Negativo
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>CCTV</td>
                                            <td>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name={`cctv_${llamadaIndex}`}
                                                        value="Positivo"
                                                        checked={llamada.cctv.estado === "Positivo"}
                                                        onChange={(e) => handleEquipoEstadoChange(llamadaIndex, 'cctv', 'estado', e.target.value)}
                                                    /> Positivo
                                                </label>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name={`cctv_${llamadaIndex}`}
                                                        value="Negativo"
                                                        checked={llamada.cctv.estado === "Negativo"}
                                                        onChange={(e) => handleEquipoEstadoChange(llamadaIndex, 'cctv', 'estado', e.target.value)}
                                                    /> Negativo
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Audio</td>
                                            <td>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name={`audio_${llamadaIndex}`}
                                                        value="Positivo"
                                                        checked={llamada.audio.estado === "Positivo"}
                                                        onChange={(e) => handleEquipoEstadoChange(llamadaIndex, 'audio', 'estado', e.target.value)}
                                                    /> Positivo
                                                </label>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name={`audio_${llamadaIndex}`}
                                                        value="Negativo"
                                                        checked={llamada.audio.estado === "Negativo"}
                                                        onChange={(e) => handleEquipoEstadoChange(llamadaIndex, 'audio', 'estado', e.target.value)}
                                                    /> Negativo
                                                </label>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="mb-3">
                                    <label><strong>Observaciones Telefonía</strong></label>
                                    <textarea
                                        value={llamada.observacionesTelefonia || ''}
                                        onChange={(e) => handleLlamadaChange(llamadaIndex, 'observacionesTelefonia', e.target.value)}
                                        className="form-control"
                                        rows={3}
                                    />
                                </div>
                            </div>
                        ))
                    ) : (
                        mantenimiento.llamadaConsolaSeguridad && mantenimiento.llamadaConsolaSeguridad.map((llamada, llamadaIndex) => (
                            <div key={llamadaIndex}>
                                <div className="mb-3">
                                    <p><strong>Consola:</strong> {llamada.consola}</p>
                                    <p><strong>Operador:</strong> {llamada.operador}</p>
                                </div>
                                <div className="mb-3">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Equipo</th>
                                                <th>Estado</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Discador</td>
                                                <td>{llamada.discador.estado}</td>
                                            </tr>
                                            <tr>
                                                <td>RPS</td>
                                                <td>{llamada.rps.estado}</td>
                                            </tr>
                                            <tr>
                                                <td>CCTV</td>
                                                <td>{llamada.cctv.estado}</td>
                                            </tr>
                                            <tr>
                                                <td>Audio</td>
                                                <td>{llamada.audio.estado}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="mb-3">
                                    <p><strong>Observaciones Telefonía</strong>: {llamada.observacionesTelefonia}</p>
                                </div>
                            </div>
                        ))
                    )}
                </div>

                <div className="section">
                    <h5>Observaciones</h5>
                    {isEditing ? (
                        <div className="form-group">
                            <label htmlFor="observaciones"><strong>Observaciones</strong></label>
                            <textarea
                                id="observaciones"
                                value={editedMantenimiento.observaciones || ''}
                                onChange={handleChangeObservaciones}
                                className="form-control"
                                rows={5}
                                disabled={!isEditing}
                            />
                        </div>
                    ) : (
                        <p>{mantenimiento.observaciones}</p>
                    )}
                </div>

                <div className="section">
                    <h5>DT15V6</h5>
                    {isEditing ? (
                        <div>
                            <div className="form-group">
                                <label htmlFor="nombreApellido">Nombre y Apellido</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="nombreApellido"
                                    value={editedMantenimiento.dt15v6 ? editedMantenimiento.dt15v6.nombreApellido : ''}
                                    onChange={(e) => {
                                        const value = e.target.value || ''; // Manejar el caso de e.target.value null o undefined
                                        setEditedMantenimiento(prevState => ({
                                            ...prevState,
                                            dt15v6: {
                                                ...prevState.dt15v6,
                                                nombreApellido: value,
                                            },
                                        }));
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="cargo">Cargo</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="cargo"
                                    value={editedMantenimiento.dt15v6 ? editedMantenimiento.dt15v6.cargo : ''}
                                    onChange={(e) => {
                                        const value = e.target.value || ''; // Manejar el caso de e.target.value null o undefined
                                        setEditedMantenimiento(prevState => ({
                                            ...prevState,
                                            dt15v6: {
                                                ...prevState.dt15v6,
                                                cargo: value,
                                            },
                                        }));
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        mantenimiento.dt15v6 && (
                            <div>
                                <p><strong>Nombre y Apellido</strong>: {mantenimiento.dt15v6.nombreApellido}</p>
                                <p><strong>Cargo</strong>: {mantenimiento.dt15v6.cargo}</p>
                            </div>
                        )
                    )}
                </div>

                <div className="section">
                    <h5>Cliente</h5>
                    {isEditing ? (
                        <div>
                            <div className="form-group">
                                <label htmlFor="clienteNombreApellido">Nombre y Apellido</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="clienteNombreApellido"
                                    value={editedMantenimiento.cliente ? editedMantenimiento.cliente.nombreApellido : ''}
                                    onChange={(e) => {
                                        const value = e.target.value || ''; // Manejar el caso de e.target.value null o undefined
                                        setEditedMantenimiento(prevState => ({
                                            ...prevState,
                                            cliente: {
                                                ...prevState.cliente,
                                                nombreApellido: value,
                                            },
                                        }));
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="clienteCargo">Cargo</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="clienteCargo"
                                    value={editedMantenimiento.cliente ? editedMantenimiento.cliente.cargo : ''}
                                    onChange={(e) => {
                                        const value = e.target.value || ''; // Manejar el caso de e.target.value null o undefined
                                        setEditedMantenimiento(prevState => ({
                                            ...prevState,
                                            cliente: {
                                                ...prevState.cliente,
                                                cargo: value,
                                            },
                                        }));
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        mantenimiento.cliente && (
                            <div>
                                <p><strong>Nombre y Apellido</strong>: {mantenimiento.cliente.nombreApellido}</p>
                                <p><strong>Cargo</strong>: {mantenimiento.cliente.cargo}</p>
                            </div>
                        )
                    )}
                </div>

                <div className="section">
                    <div className="form-group">
                        <div>
                            <h5>Soporte Fotográfico</h5>
                        </div>
                        {mantenimiento.soporteFotografico && mantenimiento.soporteFotografico.length > 0 ? (
                            <div className="image-container">
                                {mantenimiento.soporteFotografico.map((url, index) => (
                                    <div key={index} className="image-item">
                                        <img
                                            src={url}
                                            alt={`Imagen ${index + 1}`}
                                            style={{ maxWidth: '200px' }}
                                        />
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p>No hay imágenes disponibles...</p>
                        )}
                    </div>

                </div>

            </div>
            {
                userRole === "admin" && (
                    <div className="col-md-12 botones">
                        {isEditing ? (
                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={handleSaveChanges}
                            >
                                Guardar Cambios
                            </button>
                        ) : (
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => setIsEditing(true)}
                            >
                                Editar Mantenimiento
                            </button>
                        )}
                        <button
                            type="button"
                            className="btn btn-danger"
                            onClick={handleDeleteMantenimiento}
                        >
                            Eliminar Mantenimiento
                        </button>
                    </div>
                )
            }
        </div >
    );
};

export default DetalleMantenimiento;
